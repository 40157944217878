.recipes-list-page {
  display: block;
  position: relative;
  background-image: url('../images/pages/index/blocks/texture-1.jpg');
  background-repeat: repeat;
  z-index: 100;


  .breadcrumbs-list {
    @include respond-down(medium) {
      opacity: 0;
    }
  }

  .text-block {
    @include respond(small) {
      margin-top: -30px;
    }
  }

  .page-title {
    font-family: $font;
    font-weight: $bold;
    color: $red;
    text-align: center;
    @include respond-property(font-size, 30px, 35px, 40px, 40px);
  }

  .content {
    position: relative;
    @include respond-property(padding-bottom, 75px, 95px, 100px, 150px);

    .categories-filter-choose {
      background: $red;
      @include respond(small) {
        margin-top: 35px;
      }
    }

    .filter__items {
      justify-content: center;

      @include respond-down(small) {
        width: 100%;
      }

      @at-root .filter__item {

      }
    }

    .filter__item {
      text-decoration: none;
      @include respond(medium) {

      }
    }

    .recipes-block {
      padding: 0 !important;

      .block-content.slider {
        .recipes {
          .recipe-row {
            .recipe-item {
              &:nth-child(1) {
                @include respond-up(middle) {
                  //margin: 0 180px 0 60px;
                }
              }
            }
          }
        }
      }

    }

  }

  .more-recipes {
    opacity: 0;
    margin-bottom: 20px !important;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
