.hamburger {
  display: block;
  width: 31px;
  height: 19px;
  @include respond(medium) {
    margin: 47px 0 0 14px;
    }
  @include respond(small) {
    margin: 38px 0 0 -7px;
    }
  }



.mobile-menu {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  min-height: 410px;
  height: 100vh;
  max-height: 690px;
  background: $red;
  transition: all .3s ease-out;
  &.closed {
    left: -100%;
    }
  .wrapper {
    padding: 52px 0 179px 35px;
    .menu-items {
      display: flex;
      flex-direction: column;
      .menu-item {
        font-weight: $bold;
        font-size: 15px;
        color: $white;
        line-height: 1;
        .push-nav {
          display: block;
          position: fixed;
          padding: 52px 0 179px 35px;
          top: 0;
          left: -100%;
          width: 270px;
          min-height: 410px;
          height: 100vh;
          max-height: 690px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          background-color: #e6040c;
          transition: all .3s ease-out;
          li {
            white-space: normal;
          }
          li:first-child{
            margin-top: 55px;
          }
          li:not(:last-child) {
            margin-bottom: 30px;
            max-width: 205px;
          }
          .children-items-main, .subchildren-items-main  {
            background: #f9eacb;
            padding: 10px 20px 10px 20px;
            border-radius: 4px;
            a {
              color: #000;
            }
          }
        }
        &.opened {
          z-index: 15;
          & >.push-nav {
            left: 0;
            opacity: 1;
            .opened {
              z-index: 15;
              & >.push-nav {
                left: 0;
                opacity: 1;
              }
            }
          }
        }
        &:not(:last-child) {
          margin: 0 0 30px 0;
          }
        }
      }
    .social {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 32px 0 0 0;
      .social-item {
        &:not(:last-child) {
          margin: 0 10px 0 0;
          }
        }
      }
    }

  .push-nav {
    display: block;
    position: fixed;
    padding: 52px 0 179px 35px;
    top: 0;
    left: -100%;
    width: 241px;
    min-height: 410px;
    height: 100vh;
    max-height: 690px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-color: #e6040c;
    transition: all .3s ease-out;
    li:first-child{
      margin-top: 55px;
    }
    li:not(:last-child) {
      margin-bottom: 30px;
      max-width: 185px;
    }
    .children-items-main  {
      background: #f9eacb;
      padding: 10px 20px 10px 20px;
      border-radius: 4px;
      a {
        color: #000;
      }
    }
  }
  .vegetables {
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    &.garlic {
      width: 43px;
      height: 47px;
      top: 18px;
      left: 28px;
      background-image: url('../images/parts/header/garlic.png');
      }
    &.spinach {
      width: 42px;
      height: 43px;
      left: 104px;
      top: 45px;
      background-image: url('../images/parts/header/spinach.png');
      }
    &.tomato {
      width: 90px;
      height: 90px;
      right: 10px;
      bottom: 23px;
      background-image: url('../images/parts/header/tomato.png');
      }
    }
  .cross {
    display: block;
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 50;
    .svg-icon {
      &.icon-cross {

        }
      }
    }
  }