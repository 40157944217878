body.jobs-page {
  position: relative;
  //background-image: url('../images/parts/texture-900.jpg');
  //background-size: 912px 937px;
  //background-repeat: repeat;
  background: #ffffff;
  z-index: 1;
  overflow-x: hidden;

  .breadcrumbs-block {
    @include respond-down(small) {
      opacity: 0;
      pointer-events: none;
    }
  }

  .bg-images {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    * {
      display: block;
      position: absolute;
      background-size: contain;
    }

    .jobs__left {
      width: 256px;
      height: 1069px;
      top: 342px;
      left: 0;
      background-image: url('../images/pages/jobs/jobs__left.png');
    }

    .jobs__right {
      width: 258px;
      height: 1177px;
      top: 222px;
      right: 0;
      background-image: url('../images/pages/jobs/jobs__right.png');
    }
  }
}

.jobs-page {
  .content {
    position: relative;
    z-index: 2;

    .page-title {
      margin: 15px 0 0;
      font-weight: $bold;
      font-size: 50px;
      color: #DD2020;
      letter-spacing: 0;
      font-family: $font;
      text-align: center;
      @include respond-down(small) {
        font-size: 40px;
      }
    }

    .page-description {
      max-width: 600px;
      margin: 46px auto 48px;
      font-weight: $semibold;
      font-size: 20px;
      color: #000000;
      line-height: calc(25 / 20);
      @include respond-down(small) {
        margin: 10px auto 25px;
        font-size: 16px;
      }

      a {
        display: inline-block;
        text-decoration: underline;
      }
    }

    .items {
      @include respond-down(small) {
        margin: 0 -40px;
      }

      .item {
        display: block;
        background: $white;
        padding: 50px 106px;
        @include respond(middle) {
          padding: 50px 80px;
        }
        @include respond(medium) {
          padding: 50px 50px;
        }
        @include respond-down(small) {
          padding: 25px 30px;
        }

        &:not(:last-child) {
          margin-bottom: 25px;
          @include respond-down(small) {
            margin-bottom: 60px;
          }
        }

        .item-title {
          max-width: 500px;
          font-weight: $bold;
          font-size: 20px;
          color: $red;
          line-height: 25px;
          font-family: $font;
        }

        .item-description {
          max-width: 500px;
          margin: 25px 0 0;

          &, p {
            font-size: 16px;
            color: #000000;
            line-height: 25px;
          }

          strong {
            font-weight: $semibold;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}