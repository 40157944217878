.success_cart{
  background: #e6040c;
  color: #fff;
  position: sticky;
  bottom: 0px;
  width: 100%;
  display: none;
  padding: 0 50px;
  z-index: 10;

  .success_cart_wrapper{

    display:flex;
    width:100%;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;

    p{
      margin: 30px 0px;
    }

    a{
      margin: 30px 0px;
      display: block;
      background-color: #fff;
      color: #e6040c;
      text-decoration: none;
      text-align: center;
      padding: 20px 30px;
      border-radius: 27px;
      transition: all .3s;

      &:hover{
        color: #000;
      }
    }
  }

 

}
.list-cart {
  background: white;
  @include respond(ssmall) {
    margin-left: -25px;
    margin-right: -25px;
  }
  > li {
    padding: 25px 30px;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(ssmall) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
    &.total-item-cart {
      border-bottom: none;
      justify-content: flex-end;
      margin-right: 50px;
      padding: 35px;
      @include respond(ssmall) {
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0;
      }
      > span {
        display: inline-block;
        width: 25px;
        text-align: right;
      }
    }
    > img {
      max-width: 72px;
    }
    .title-cart {
      width: 50%;
      line-height: 130%;
      @include respond(small) {
        width: 100%;
      }
    }
    
  }
  .list-description {
    li {
      line-height: 130%;
    }
  }
  .name-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(small) {
      width: 40%;
      flex-direction: column;
      align-items: flex-start;
    }
    @include respond(ssmall) {
      width: 100%;
      margin: 25px 0;
    }
  }
  .list-count-cart  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    .button-count {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #D8D8D8;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #D8D8D8;
      font-size: 30px;
      &:hover {
        color: white;
        background: #E6040C;
      }
    }
    span {
      display: inline-block;
      font-size: 18px;
      color: black;
      width: 100%;
      text-align: center;
    } 
  }
}

body.cart-page {
    position: relative;
    background-image: url('/static/images-processed/texture-900-c2362ebcdb2027d8e702910f118a56f7.jpg');
    background-size: 912px 937px;
    background-repeat: repeat;
    z-index: 100;
  .cart-page {
    h2, .order-title {
      font-family: inherit;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 35px;
    }
  }
}

.form-checkout {
  background: white;
  padding: 52px 43px 36px;
  border: 4px solid #e6040c;
  border-radius: 25px;
  box-shadow: 0 2px 25px 0 rgba(0,0,0,0.14);
  overflow: hidden;
  margin-top: 45px;
  @include respond (ssmall) {
    padding: 25px;
    border: none;
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 0;
    padding-right: 0;
  }
  
  .width-large-7.width-medium-7 {
    @include respond (small) {
      margin-top: 25px !important;
    }
  }
  .last-input {
      margin-top: 25px;
  }
  > .column {
    padding-left: 20px;
    padding-right: 20px;
  }
  .column  {
    
    + h4 {
      @include respond (small) {
        margin-top: 25px;
      }
    }
  }
  .large-12 {
    margin-top: 25px;
  }
  .submit-container {
    text-align: center;
  }
  .button-submit {
    height: 53px;
    min-width: 250px;
    display: inline-block;
    margin-top: 25px;
  }
  h3 {
    font-family: inherit !important;
    margin-top: 0 !important;
  }
  h4 {
    margin-bottom: 25px;
    font-family: inherit;
    + p {
      margin-bottom: 25px;
    }
  }
  label {
    display: none;
  }
  .c-field {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    + p {
      margin-bottom: 25px;
    }
  }
  [type=checkbox] {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    &:checked {
      background: red;
    }
  }
  [for=OrderForm_rules],
  [for=OrderForm_pickup],
  [for=OrderForm_all_russia] {
    display: inline-block;
  }
  .rules-input {
    display: flex;
    align-items: center;
    label {
      color: black;
      font-size: 16px;
      margin-left: 25px;
    }
  }
  .sub-title {
    font-size: 18px;
    margin-bottom: 34px !important;
  }
}

.scale-up-ver-bottom {
  -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.scale-up-ver-bottom-reverse {
  -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
}

@media screen and (max-width: 1920px) {
  .width-large-5 {
    width: 41.667%;
  }

  .width-large-6 {
    width: 50%;
  }

  .width-large-7 {
    width: 58.333%;
  }


  .width-large-12 {
    width: 100%;
  }
}


@media screen and (max-width: 768px) {
  .width-medium-7 {
    width: 100%;
  }
  .width-medium-5 {
    width: 100%;
  }
}



// @include respond (small) {
//   .width-medium-7 {
//     width: 58.333%;
//   }
// }
// @include respond (small) {
//   .width-medium-5 {
//     width: 41.667%;
//   }
// }


/* ----------------------------------------------
 * Generated by Animista on 2020-3-30 19:33:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
  0% {
    display: block;
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    display: block;
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
