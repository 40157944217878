.breadcrumbs-block {
  display: block;
  padding: 195px 0 0 100px;

  @include respond(middle) {
    padding: 195px 0 0 67px;
  }
  @include respond(medium) {
    padding: 150px 0 0 45px;
  }
  @include respond(small) {
    padding: 130px 0 0 30px;
  }

  .breadcrumbs-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      font-size: 14px;
      color: rgba($black000, .7);
      margin: 10px 0 0 0;

      &:nth-child(1) {
        white-space: nowrap;
      }

      &.delimiter {
        padding: 0 17px;
        user-select: none;
      }
    }

    .item-link {
      font-size: 14px;
      color: rgba($black000, .7);
      text-decoration: underline;
    }
  }
}