body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $body-font-size;
  font-family: $font;
  background: $white;
  color: $black;
  scroll-behavior: smooth;

  a {
    text-decoration: none;
    color: $white;
  }

  a.red, .c-red {
    color: $red;
  }
}

.scroll-none {
  overflow-y: hidden;
}

.pt-icon {
  display: flex;
  align-items: center;
  @at-root {
    &_color {
      &_red {
        &, .svg-icon {
          color: #E6040C;
        }
      }
    }

    &__icon {
      display: inline-block;
    }

    &__text {
      display: inline-block;

    }

    &_social {
      .pt-icon__icon {
        &, .svg-icon {
          width: 20px;
          height: 20px;
        }

        margin-right: 10px;
      }

      .pt-icon__text {

      }
    }

  }
}

.c-black {
  color: $black;
}

form{
  position: relative;
  &.success:after {
    content: "Спасибо, данные успешно отправлены!";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    position: absolute;
    background: #ffffff;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    border-radius: 19px;
    border: 1px solid #feaf00;
    transform: rotateY(0deg);
  }

  &.form-OrderForm.success:after {
    content: "Спасибо! Ваш заказ поступил в обработку. Ожидайте звонка менеджера.";
  }
}