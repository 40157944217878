body.opt-page {
  position: relative;
  //background-image: url('../images/parts/texture-900.jpg');
  //background-size: 912px 937px;
  //background-repeat: repeat;
  background: #ffffff;
  z-index: 1;
  overflow-x: hidden;

  .footer {
    background-image: url('../images/parts/texture-900.jpg');
    background-size: 912px 937px;
    background-repeat: repeat;
  }
}

.opt-page {
  position: relative;
  overflow-x: hidden;

  .breadcrumbs-block {
    @include respond(small) {
      padding-top: 100px;
      opacity: 0;
    }
  }

  .intro {
    display: flex;
    margin: 65px 0 0 0;
    @include respond(small) {
      margin: 0;
    }

    &__image {
      display: block;
      margin: 100px 0 0 auto;
      height: 100%;
      object-fit: contain;
      @include respond(middle) {
        margin: 120px 0 0 auto;
      }
      @include respond-down(medium) {
        margin: 50px auto -50px;
        height: 500px;
      }
    }

    &__text-block {

    }

    &__title {
      margin: 0 0 57px 0;
      @include respond(small) {
        margin: 0 0 20px 0;
      }
    }

    &__text {
      font-size: 20px;
      color: $black;
      line-height: calc(24 / 20);
      @include respond(medium) {
        font-size: 18px;
      }
      @include respond(small) {
        font-size: 16px;
      }
    }
  }

  .contact {
    display: flex;
    margin: 70px 0;
    padding: 45px 60px 45px 103px;
    background: #D8D8D8;
    justify-content: space-between;
    align-items: center;
    @include respond-down(medium) {
      flex-direction: column;
      padding: 39px 83px 38px 83px;
    }
    @include respond(small) {
      padding: 20px 25px 40px;
      width: 100vw;
      margin-left: -40px;
    }

    &__text {
      max-width: 430px;
      font-size: 25px;
      color: $black;
      text-align: center;
      line-height: calc(30 / 25);
      @include respond-down(medium) {
        font-size: 23px;
        line-height: calc(28 / 23);
      }
      @include respond(small) {
        font-size: 18px;
        line-height: calc(22 / 18);
      }
    }

    &__form {
      display: flex;
      position: relative;
      height: 54px;
      width: 100%;
      max-width: 533px;

      .label {
        display: none;
      }

      &.success {
        &:before {
          content: 'Спасибо. Мы ответим Вам в ближайшее время';
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          text-align: center;
          background: #D8D8D8;
          z-index: 10;
        }


      }

      @include respond-down(medium) {
        margin: 40px 0 0 0;
        max-width: 381px;
        height: auto;
        flex-direction: column;
      }
      @include respond(small) {
        margin: 17px 0 0 0;
      }
    }

    input {
      flex: 0 0 50%;
      width: 100%;
      height: 54px !important;
      border: 1px solid #979797;

      &, &::placeholder {
        opacity: 1;
        font-size: 20px;
        color: $black;
        text-align: center;
        @include respond(medium) {
          font-size: 18px;
        }
        @include respond(small) {
          font-size: 16px;
        }
      }
    }

    &__button {
      min-height: 54px;
      height: 54px !important;
      width: 100%;
      flex: 0 0 50%;
      box-shadow: none;
      border-radius: 0;
      font-weight: $bold;
      font-size: 20px;
      color: $white;
      text-align: center;

      @include respond-down(medium) {
        margin: 27px 0 0 0;
      }
      @include respond(medium) {
        font-size: 18px;
      }
      @include respond(small) {
        font-size: 16px;
        margin: 17px 0 0 0;
      }
    }
  }



  .benefits {
    padding: 0 0 50px 0;

    &__title {

    }

    &__items {
      display: flex;
      margin: 30px 0 0 0;
      justify-content: space-between;
      @include respond-down(medium) {
        flex-wrap: wrap;
      }
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 0 0 25%;
      text-align: center;
      @include respond(medium) {
        flex: 0 0 50%;
        &:nth-child(n+3) {
          margin: 42px 0 0 0;
        }
      }
      @include respond(small) {
        flex: 0 0 100%;
        &:not(:last-child) {
          margin: 0 0 20px 0;
        }
      }
    }

    &__item_icon {
      display: flex;
      width: 140px;
      height: 140px;
      position: relative;
      justify-content: center;
      align-items: center;

      .svg-icon {
        position: absolute;
      }
    }

    &__item_title {
      margin: 20px 0 0 0;
      max-width: 293px;
      font-size: 21px;
      color: #331B09;
      text-align: center;
      @include respond(small) {
        font-size: 20px;
      }
    }

  }

  .our-products {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 626px;
    padding: 114px 0 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../images/pages/opt/opt__our-products.png');

    .owl-stage-outer {
      overflow: visible;

      .owl-item {
        opacity: 0;
        transition: all .2s ease;

        &.active {
          opacity: 1;
        }
      }
    }

    .owl-nav {
      .owl-prev {
        @include respond(small) {
          left: 5px;
          transform: scale(.7);
        }
      }

      .owl-next {
        @include respond(small) {
          right: 5px;
          transform: scale(.7);
        }
      }
    }

    &__title {
      margin: 0 0 60px 0;

      @include respond(small) {
        margin: 0 0 30px 0;
      }
    }

    &__slider {
      .owl-prev {
        left: -70px;
      }

      .owl-next {
        right: -70px;
      }
    }

    &__item {
      display: flex;
      //flex: 0 0 25%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @at-root &_image {
        display: block;
        height: 250px;
        @include respond(small) {
          height: auto;
        }

        img {
          max-width: none;
        }
      }
      @at-root &_title {
        margin: 30px 0 0 0;
        font-weight: $extrabold;
        font-size: 18px;
        color: #331B09;
        text-align: center;
        line-height: 21px;
        text-transform: uppercase;
      }
    }
  }

  .stm {
    display: block;
    width: 100%;
    padding: 60px 0 100px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/pages/opt/opt__stm.jpg');

    @include respond(small) {
      background-image: url('../images/pages/opt/opt__stm-small.png');
      margin-top: -50px;
      padding: 40px 0 0 0;
    }

    &__text-block {
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond(small) {
        flex-direction: column;
      }
    }

    &__bottle {
      display: block;
      flex: 0 0 280px;
      width: 322px;
      height: 660px;
      background-image: url("../images/pages/index/blocks/wholesaler-block/sauce.png");
      background-repeat: no-repeat;
      background-size: contain;
      @include respond-up(large) {
        margin-right: 40px;
      }

      @include respond(middle) {
        width: 242px;
        height: 495px;
      }

      @include respond-down(medium) {
        width: 218px;
        height: 490px;
        background-position: center center;
      }

      @include respond(small) {

      }
    }

    &__right {
      max-width: 600px;
    }

    &__text {
      margin: 30px 0 0 0;
      font-size: 20px;
      line-height: 30px;
      @include respond(medium) {
        font-size: 18px;
      }
      @include respond(small) {
        font-size: 16px;
      }
    }

    &__title {

    }

    &__right {

    }

    .contact {
      margin: 0;
      @include respond-down(medium) {
        margin: 20px 0 0 0;
      }
      @include respond(small) {
        margin-left: -40px;
      }
    }
  }
}




