@import "~owl.carousel/dist/assets/owl.carousel.css";

.owl-carousel {
  position: relative;
}

.owl-nav {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 10;

  .owl-prev {
    position: absolute;
    left: 18.75%;
    font-size: 40px;
    box-shadow: none;
    width: 27px;
    height: inherit;

    @include respond(middle) {
      left: 15%;
    }

    .carousel-prev {
      display: block;
      width: 27px;
      height: 40px;
      background: url("../svg/carousel-arrow.svg") no-repeat center center;
      background-size: contain;
      @include respond-down(medium) {
        width: 22px;
        height: 32.6px;
      }
      @include respond-down(small) {
        width: 16px;
        height: 24px;
      }

      &.black {
        width: 20px;
        height: 10px;
        background-image: url('../svg/black-arrow.svg');
      }
    }
  }

  .owl-next {
    position: absolute;
    right: 18.75%;
    font-size: 40px;
    box-shadow: none;
    width: 27px;
    height: inherit;

    @include respond(middle) {
      right: 15%;
    }

    .carousel-next {
      display: block;
      width: 27px;
      height: 40px;
      background: url("../svg/carousel-arrow-red.svg") no-repeat center center;
      background-size: contain;
      @include respond(medium) {
        width: 22px;
        height: 32.6px;
      }
      @include respond-down(small) {
        width: 16px;
        height: 24px;
      }

      &.black {
        width: 20px;
        height: 10px;
        background-image: url('../svg/black-arrow.svg');
        transform: rotateZ(180deg);
      }


    }
  }
}

button {
  &.owl-prev, &.owl-next {
    background: $t;
  }
}