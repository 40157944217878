.upper {
  text-transform: uppercase !important;
  }

.bold {
  font-weight: bold !important;
  }

.no-select {
  user-select: none !important;
  }

.text-center {
  text-align: center !important;
  }

.d-flex {
  display: flex !important;
  }

.jc-center {
  justify-content: center !important;
  }

.ac-center {
  align-content: center !important;
  }

.ai-center {
  align-items: center !important;
  }

.ac-center {
  align-content: center !important;
  }

.t-left {
  text-align: left !important;
  }

.t-right {
  text-align: right !important;
  }

.w-100 {
  width: 100%;
  }

.h-100 {
  height: 100%;
  }

.ws-nowrap {
  white-space: nowrap !important;
  }

.p-rel {
  position: relative;
  }

.d-none {
  display: none;
  }

.small-hidden {
  @include respond(small) {
    display: none;
    }
  }