.catalog-page {
  position: relative;
  //background-image: url('../images/parts/texture-900.jpg');
  //background-size: 912px 937px;
  //background-repeat: repeat;
  background: #ffffff;
  z-index: 100;
  @include respond-property(padding-bottom, 75px, 95px, 100px, 150px);

  &.category-id-17 {
    background: #ffffff;
  }

  .wrapper {
    position: relative;

    .bg-food {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      pointer-events: none;
      z-index: 9;

      & > span {
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center
      }

      .tomatoes {
        width: 193px;
        height: 315px;
        top: 100px;
        left: 0;
        background-image: url('../images/pages/catalog/food/tomatoes.png');
      }

      .things {
        width: 196px;
        height: 347px;
        top: 568px;
        left: 0;
        background-image: url('../images/pages/catalog/food/things.png');
      }

      .things-right {
        width: 171px;
        height: 371px;
        top: 0;
        right: 0;
        background-image: url('../images/pages/catalog/food/things-right.png');
      }

      .bell-pepper {
        width: 201px;
        height: 365px;
        top: 1000px;
        right: 0;
        background-image: url('../images/pages/catalog/food/bell-pepper.png');
      }

      .parsley {
        width: 130px;
        height: 273px;
        top: 500px;
        right: 0;
        background-image: url('../images/pages/catalog/food/parsley.png');
      }
    }

    .categories-filter {
      @include respond-up(medium) {
        margin: 55px 0 0 0;
      }

      .category {
        text-decoration: none;
      }
    }

    .benefits {
      display: flex;
      position: relative;
      width: 100%;
      margin: 57px 0 50px 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 10;
      @include respond(small) {
        margin: 33px 0 50px 0;
      }

      .benefit {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include respond-down(medium) {
          flex-direction: column;
        }
        @include respond(medium) {
          width: 25%;
        }
        @include respond(small) {
          width: 50%;
          &:nth-child(n+3) {
            margin-top: 33px;
          }
        }

        .icon {
          width: 64px;
          height: 64px;

          @include respond-up(medium) {
            margin-right: 18px;
          }

          .svg-icon {

          }
        }

        .title {
          text-align: center;
          @include respond-down(medium) {
            margin: 20px 0 0 0;
          }

        }
      }
    }

    .categories {
      position: relative;
      z-index: 10;

      .category {
        .title {
          font-size: 24px;
          font-weight: $bold;
          color: $red;
        }

        .subcategory {
          margin: 0 0 40px 0;

          .title {
            font-size: 24px;
            font-weight: $bold;
            color: $red;
          }

          .items {
            display: grid;
            margin: 42px 0 0 0;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 40px;
            grid-row-gap: 40px;
            grid-template-rows: auto;
            @include respond(medium) {
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 53px;
              grid-row-gap: 50px;
            }
            @include respond(small) {
              grid-template-columns: 100%;
              grid-column-gap: 40px;
              grid-row-gap: 35px;
            }
          }

          &.small-title-hidden {
            @include respond(small) {
              .title {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .subcategory-title {
    @include respond(small) {
      max-width: 231px;
      margin: 8px auto 0 auto;
      font-weight: $bold;
      font-size: 22px;
      color: $red;
      text-align: center;
    }
  }
}

body.catalog-page {

  .footer {
    @include respond(middle) {
      position: relative;
      margin-top: -100px;
      z-index: 9;
    }
  }
}
.categories-filter-choose {
  display: flex;
  width: 100%;
  margin: 67px 0 0 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 57px;
  border-radius: 28.5px;
  background: $black000;
  color: $white;
  font-size: 20px;
  box-shadow: none;
  transition: background .2s ease-out;

  @include respond(small) {
    font-weight: $semibold;
  }

  &.active {
    background: $red;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
