body.contacts-view-page {
  position: relative;
  background-image: url('../images/parts/texture-900.jpg');
  background-size: 912px 937px;
  background-repeat: repeat;
  z-index: 1;
  overflow-x: hidden;
}

.contacts-view-page {
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .sides-pics {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    .key-and-pen {
      position: absolute;
      top: 397px;
      left: -7px;
      width: 235px;
      height: 471px;
      background: url("/static/images/pages/contacts/bg_key-and-pen.png") no-repeat 100% 100%;
    }

    .flowers-in-pot {
      position: absolute;
      top: 835px;
      left: -5px;
      width: 305px;
      height: 442px;
      background: url("/static/images/pages/contacts/bg_flowers-in-pot.png") no-repeat 100% 100%;
    }

    .piece-of-map {
      position: absolute;
      top: 267px;
      right: -8px;
      width: 321px;
      height: 667px;
      background: url("/static/images/pages/contacts/bg_piece-of-map.png") no-repeat 100% 100%;
    }

    .compass {
      position: absolute;
      top: 956px;
      right: 5px;
      width: 284px;
      height: 284px;
      background: url("/static/images/pages/contacts/bg_compass.png") no-repeat 100% 100%;
    }
  }

  .content {
    position: relative;
    padding-top: 54px;

    h2 {
      font-family: "Proxima Nova", serif;
      font-size: 30px;
      font-weight: bold;
      line-height: 0.8;
      color: #331b09;
      margin-bottom: 46px;

    }

    .departments {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;

      @include respond-down(medium) {
        flex-direction: column;
      }

      &__item {
        width: 48.4%;
        @include respond-down(medium) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        h3 {
          position: relative;
          font-family: "Proxima Nova", serif;
          font-size: 24px;
          font-weight: bold;
          line-height: 62px;
          margin-bottom: 28px;
          padding-left: 67px;

          @include respond-down(small) {
            line-height: 1.3;
          }

          &.region {
            margin-bottom: 14px;
          }

          &:before {
            content: ' ';
            position: absolute;
          }

          &.kirov:before {
            top: 0;
            left: 0;
            width: 50px;
            height: 62px;
            background: url("/static/images/pages/contacts/ic_gerb_kirov.svg");
          }

          &.region:before {
            top: 0;
            left: 0;
            width: 52px;
            height: 62px;
            background: url("/static/images/pages/contacts/ic_gerb_rf.svg");
          }
        }

        .contacts {
          padding-left: 65px;
          margin-bottom: 40px;
          @include respond-down(small) {
            padding-left: 0;
          }

          h4 {
            font-size: 24px;
            font-weight: bold;
            font-family: "Proxima Nova", serif;
            margin-bottom: 28px;
          }

          .item {
            display: flex;
            font-size: 20px;
            width: 100%;
            @include respond-down(small) {
              flex-direction: column;
            }

            .name {
              position: relative;
              width: 217px;
              padding-left: 54px;
              line-height: 36px;

              &:before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 36px;
                height: 36px;
              }

              &.phone:before {
                background: url("/static/images/pages/contacts/ic_phone.svg");
              }

              &.email:before {
                background: url("/static/images/pages/contacts/ic_email.svg");
              }
            }

            ul {
              @include respond-down(small) {
                padding-left: 56px;
              }
            }

            li {
              line-height: 36px;
              margin-bottom: 10px;

              a {
                color: #e3000f;
              }
            }
          }
        }
      }
    }
  }
}

.contacts__place {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
  font-size: 20px;
  line-height: 1.5;
  padding: 27px 22px 30px;

  @include respond(medium) {
    position: absolute;
    max-width: 597px;
    top: 200px;
    right: 46px;
  }
  @include respond(small) {
    margin-left: -40px;
    width: 100vw;
  }

  &.map {
    box-shadow: none;
    width: 100% !important;
    margin-left: 0 !important;

    .item {
      flex-direction: column;

      div {

        &:first-child {
          flex-basis: auto;
          margin-bottom: 15px;
        }
      }
    }

  }

  .item {
    display: flex;
    @include respond-down(medium) {
      flex-direction: column;
    }

    div:last-child {
      @include respond-down(small) {
        font-size: 15px;
      }
    }

    &:first-child {
      margin-bottom: 32px;

      div:first-child:before {
        top: 5px;
        left: 2px;
        width: 19px;
        height: 24px;
        background: url("/static/images/pages/contacts/ic_place.svg");
      }
    }

    &:last-child {

      div:first-child:before {
        top: 4px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("/static/images/pages/contacts/ic_calendar.svg");
      }
    }

    div:first-child {
      flex-basis: 260px;
      position: relative;
      padding-left: 44px;
      @include respond-down(medium) {
        flex-basis: auto;
        padding-left: 38px;
      }
    }

    div:first-child:before {
      content: '';
      position: absolute;
    }
  }
}

.contacts__map {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 787px;
  @include respond-down(medium) {
    width: 100vw;
    max-width: none;
  }
  @include respond(medium) {
    margin-left: -60px;
    height: 571px;
  }
  @include respond(small) {
    height: 290px;
    margin-left: -40px;
  }
}