$plyr-color-main: $white;
$plyr-control-radius: none;

@import "~plyr/src/sass/plyr";

.plyr__control--overlaid {
  border-radius: 50%;
  box-shadow: none !important;
  background: $t !important;
  padding: 0;

  svg {
    width: 120px;
    height: 120px;
  }

  @include respond(middle) {
    transform-origin: 0 0;
    transform: scale(.75) translate(-50%, -50%);
  }
  @include respond(middle) {
    transform-origin: 0 0;
    transform: scale(.75) translate(-50%, -50%);
  }
  @include respond(small) {
    transform-origin: 0 0;
    transform: scale(.4) translate(-50%, -50%);
  }
}

.plyr__poster {
  background-size: cover;
}

.plyr.plyr--full-ui.plyr--video {
  //opacity: 0;

  &.plyr__poster-enabled {
    opacity: 1;
  }

  &.plyr--playing {
    .plyr__controls {
      opacity: 1;
    }
  }

  .plyr__controls {
    opacity: 0;
  }
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control {
  &, &:hover {
    background: $t;
    box-shadow: none;
    border: none;
  }
}

.plyr {
  &, .plyr {
    min-height: 100%;
    height: 100%;
  }

  .plyr__video-wrapper {
    padding: 0 !important;
    min-height: 100%;
  }
}

.videos {
  .plyr__video-wrapper {
    height: 400px;

    @include respond(middle) {
      height: 300px;
    }
    @include respond(medium) {
      height: 300px;
    }
    @include respond(small) {
      height: 166px;
    }
  }
}