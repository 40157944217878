$default-input-height: 53px;

.button, button {
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: none;
  background: $red;
  cursor: pointer;
  border-radius: 26.5px;
  box-shadow: 0 5px 14px 0 rgba(230, 4, 12, 0.31);
  color: $white;
  font-size: 21px;
  user-select: none;
  transition: all ease-out .2s;

  &:hover {
    background: lighten($red, 10%);
  }

  &.remove {
    width: 30px;
    height: 30px;

    @include respond (ssmall) {
      position: absolute;
      right: 20px;
      top: 35px;
    }
  }

  @include respond-down(medium) {
    font-size: 16px;
  }

  &.transparent {
    padding: 12px 20px;
    border: 2px solid rgba($white, .5);
    background: $t;
    box-shadow: none;

    &:hover {
      box-shadow: 0;
    }
  }

  &.width {
    width: 287px;
  }

  &.height {
    height: 53px;
    @include respond(middle) {
      height: 41px;
    }
    @include respond(medium) {
      height: 30px;
    }
    @include respond(small) {
      height: 36px;
    }
  }

  @include respond(middle) {
    width: 222px;
    height: 41px;
  }
  @include respond(medium) {
    width: 250px;
    height: 36px;
  }
  @include respond(small) {
    width: 250px;
    height: 36px;
  }

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: $default-input-height;
  }
}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"],input[type="email"]';

  #{$inputs}, textarea, select {
    display: block;
    width: 100%;
    padding: 10px 22px;
    margin: 0;
    border: none;
    box-shadow: none;
    background: #f2f2f2;
    border-radius: 25px;

    &, &::placeholder {
      opacity: 1;
      font-size: 18px;
      color: #797979;
      line-height: 30px;
    }
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    resize: none;
    padding: 19px 25px;
  }

}

@mixin borderedButton() {
  width: 227px;
  height: 55px;
  margin: 92px auto 0;
  box-shadow: none;
  background: $t;
  border: 3px solid #F10009;
  border-radius: 0;
  font-weight: $bold;
  font-size: 18px;
  color: #EB0009;
  &:hover {
    background: $red;
    color: $white;

  }
  @include respond-down(medium) {
    margin: 82px auto 0;
    height: 50px;
  }
  @include respond(small) {
    margin: 60px auto 0;
  }
  &.hide {
    pointer-events: none !important;
    opacity: 0 !important;
  }

  .svg-icon {
    margin-right: 9px;
  }
}

.b-r-0 {
  border-radius: 0 !important;
}