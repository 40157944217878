@import "settings";
@import "_common/reset";
@import "_common/grid";
@import "_common/things";
@import "_parts/index";
@import "_vendors/modal";
@import "_vendors/tabs";
@import "_vendors/plyr";
@import "_vendors/owl";
@import "pages/index";
@import "pages/cart/cart";
@import "pages/_parts/_parts";
//@import "_vendors/_slick";
//@import "_vendors/_sticky";
.horeca {
  display: block;
  margin-top: -100px;
  z-index: 1;
  padding: 160px 0 150px 0;
  background: $white;
  @include respond(small) {
    padding: 160px 0 90px 0;
  }

  @at-root {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond(small) {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    &__table {
      max-width: 732px;
      width: 100%;
      @include respond-down(small) {
        max-width: none;
        zoom: .35;
        transform-origin: top left;
        .horeca__td:first-child {
          font-size: 24px;
        }
      }

      .horeca__td_icon {
        display: flex;
        margin: 0 auto;
        height: 67px;
        justify-content: center;
        align-items: center;
      }
    }

    &__title {

    }

    &__text {
      margin: 10px 0 0 0;

      &, p {
        font-size: 20px;
        line-height: 30px;
        @include respond(medium) {
          font-size: 18px;
        }
        @include respond(small) {
          font-size: 16px;
        }
      }
    }

    &__ask {
      margin: 40px 0 0 0;
      font-size: 20px;
      font-weight: 500;
      @include respond(medium) {
        font-size: 18px;
      }
      @include respond(small) {
        font-size: 16px;
      }
    }

    &__table {
      margin: 20px 0 0 0;
      border-spacing: 5px;
      border-collapse: separate;

    }

    &__head {
      .horeca__td {
        height: 173px;
        filter: saturate(0);
      }
    }

    &__row {

    }

    &__body {

    }

    &__row {
      &:hover {

      }

      &:nth-of-type(even) {
        .horeca__td {
          &:nth-of-type(odd) {
            background: #ECF6FC;
          }
        }
      }
    }

    &__td {
      height: 35px;
      text-align: center;
      vertical-align: middle;
      background: #DAEDF9;

      &:nth-child(even) {
        background: #AEDBF0;
      }

      &:nth-of-type(1) {
        padding: 0 0 0 20px;
        text-align: left;
        font-size: 20px;
        @include respond-down(medium) {
          font-size: 17px;
        }
      }

      &:nth-of-type(n+2) {
        min-width: 85px;
      }

      span {
        display: block;
        line-height: 1.2;

        &:nth-of-type(2) {
          font-size: 20px;
        }

        &:nth-of-type(n+3) {
          font-size: 14px;
        }
      }
    }

  }
}

.play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: none !important;
  background-image: url('../svg/play-btn.svg');
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%);

  @include respond(middle) {
    transform-origin: 0 0;
    transform: scale(.75) translate(-50%, -50%);
  }
  @include respond(middle) {
    transform-origin: 0 0;
    transform: scale(.75) translate(-50%, -50%);
  }
  @include respond(small) {
    transform-origin: 0 0;
    transform: scale(.4) translate(-50%, -50%);
  }
}