&.products-block {
  display: block;
  position: relative;
  height: auto;
  padding-bottom: 132px;
  margin-top: -47px;
  background: url("../images/pages/index/blocks/line.png"), url("../images/pages/index/blocks/line2.png");
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 75px, 100% 60px;
  z-index: 12;

  @include respond(middle) {
    height: auto;
    background-size: 100% 65px, 100% 50px;
  }
  @include respond(medium) {
    height: auto;
    background-size: 100% 50px, 100% 40px;
  }
  @include respond(small) {
    padding: 0 0 50px 0;
    background: #ffffff;
    height: inherit;
  }

  .vegetables {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;

    .vegetable {
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      @include respond(middle) {
        transform: scale(.77);
        transform-origin: top left;
      }

      &.bell-pepper {
        right: 0;
        bottom: 60px;
        width: 171px;
        height: 365px;
        background-image: url("../images/pages/index/blocks/products-block/bell-pepper.png");

        @include respond(middle) {
          bottom: -19px;
          right: -42px;
          transform-origin: top right;
        }
      }

      &.parsley {
        top: 297px;
        right: 0;
        width: 176px;
        height: 273px;
        background-image: url("../images/pages/index/blocks/products-block/parsley.png");

        @include respond(middle) {
          top: 220px;
          right: -42px;
          transform-origin: top right;
        }
      }

      &.pepper-2 {
        top: 45px;
        right: 0;
        width: 198px;
        height: 245px;
        background-image: url("../images/pages/index/blocks/products-block/pepper-2.png");
        @include respond(middle) {
          right: -42px;
          top: 29px;
          transform-origin: top right;
        }
      }

      &.tomato {
        top: 210px;
        left: 0;
        width: 231px;
        height: 294px;
        background-image: url("../images/pages/index/blocks/products-block/tomato.png");
        @include respond(middle) {
          top: 151px;
          left: -88px;
        }
      }

      &.pepper {
        top: 285px;
        left: 0;
        width: 220px;
        height: 673px;
        background-image: url("../images/pages/index/blocks/products-block/pepper.png");
      }

      &.grass {
        bottom: 81px;
        left: 0;
        width: 188px;
        height: 295px;
        background-image: url("../images/pages/index/blocks/products-block/grass.png");
      }
    }
  }

  .block-title {
    margin: 144px 0 38px 0;
    @include respond(middle) {
      margin: 114px 0 0 0;
    }
    @include respond(medium) {
      margin: 116px 0 0;
    }
    @include respond(small) {
      margin: 40px 0 0 0;
    }

  }

  .block-content {
    @include respond(large) {
      margin: 30px -58px 0;
    }
    @include respond(middle) {
      margin: 65px -16px 0;
    }
    @include respond(medium) {
      margin: 65px 0 0 0;
    }
    @include respond(small) {
      margin: 30px 0 0 0;
    }

    .categories {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .categories-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        &:nth-child(n+2) {
          margin: 31px 0 0 0;
          @include respond(middle) {
            margin: 70px 0 0 0;
          }
        }

        &:last-child {
          justify-content: space-around;

          .category-wrapper  {
            @include respond-up(middle) {
              &:nth-child(2) {
                margin: 0;
              }
            }
          }
        }

        @include respond(medium) {
          width: 50%;
        }
        @include respond-down(medium) {
          flex-direction: column;
        }

        .category-wrapper {
          position: relative;
          @include respond-up(middle) {
            width: calc(100% / 3 - 60px);
            &:nth-child(2) {
              margin: 0 120px;
            }
          }
          @include respond-down(medium) {
            width: 100%;
            &:not(:last-child) {
              margin: 0 0 80px 0;
            }
          }
          @include respond(small) {
            &:not(:last-child) {
              margin: 0 0 40px 0;
            }
          }

          &:hover {
            @include respond-up(middle) {
              z-index: 20;
              .hover-images {
                opacity: 1;

                .hover-image {
                  transform: scale(1);
                  @include respond-down(middle) {
                    transform: scale(.775);
                    margin-top: -20px;
                  }
                }
              }
              &.has-subcategories {
                &:before {
                  @include respond-up(large) {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 329px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin-left: -32px;
                    //background: url("../images/parts/light-texture.jpg") repeat;
                    background-color: #f5f5f5;
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                  }
                }

                &:before {
                  @include respond-down(middle) {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 271px;
                    height: 100%;
                    top: -41px;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin-left: -19px;
                    //background: url("../images/parts/light-texture.jpg") repeat;
                    background-color: #f5f5f5;
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                  }
                }

                .category {

                }

                .subcategories {
                  opacity: 1;
                  z-index: 11;

                  &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 329px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin-left: -32px;
                    //background: url("../images/parts/light-texture.jpg") repeat;
                    background-color: #f5f5f5;
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                    @include respond-down(middle) {
                      width: 271px;
                      top: -41px;
                      margin-left: -19px;
                      padding: 0 0 48px 0;
                    }
                    @include respond(medium) {
                      top: -19px;
                      margin-left: 13px;
                      padding: 0 0 37px 0;
                    }
                    @include respond(small) {
                      top: -19px;
                      margin-left: 13px;
                      padding: 0 0 37px 0;
                    }
                  }
                }
              }
            }
          }

          .hover-images {
            opacity: 0;
            transition: opacity .1s ease-out;

            .hover-image {
              position: absolute;
              width: inherit;
              height: inherit;
              z-index: 8;
              transition: transform .15s ease-out;
              transform-origin: center center;
              transform: scale(.775);
              @include respond-down(middle) {
                transform: scale(.5);
              }
            }
          }

          .category {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;

            &.category-12 {
              .title {
                @include respond(large) {
                  max-width: 100px;
                }
              }
            }

            &.category-24, &.category-25 {
              @include respond-up(middle) {
                .image-wrapper {
                  .image {
                    transform: scale(.9);
                    transform-origin: center bottom;
                  }
                }
              }
            }

            .image-wrapper {
              display: flex;
              width: 100%;
              height: 240px;
              justify-content: center;
              align-items: flex-end;
              z-index: 15;

              @include respond(middle) {
                height: 167px;
                transform: scale(.9);
              }

              @include respond-down(medium) {
                height: 178px;
              }

              .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include respond-down(middle) {
                  margin: 0 auto;
                }
              }
            }

            .title {
              position: relative;
              font-size: 18px;
              color: $black;
              text-align: center;
              line-height: 21px;
              text-transform: uppercase;
              z-index: 12;
              font-weight: $extrabold;
              margin: 20px auto 0;

              @include respond(middle) {
                margin: 0 auto;
              }
              @include respond(medium) {
                margin: 11px auto 0;
                text-align: center;
              }
              @include respond(small) {
                margin: 11px auto 0;
                text-align: center;
              }

            }
          }

          .subcategories {
            opacity: 0;
            display: flex;
            position: absolute;
            width: 100%;
            margin: -1px 0 0 0;
            padding: 16px 0 11px 16px;
            flex-direction: column;
            z-index: 10;


            @include respond-down(medium) {
              display: none;
            }

            @include respond(middle) {
              padding: 11px 0 11px 19px;
            }

            .subcategory {
              position: relative;
              font-weight: $semibold;
              font-size: 18px;
              color: $black;
              line-height: 21px;
              margin: 0 0 18px 0;
              padding-right: 30px;

              @include respond(middle) {
                margin: 0 0 5px 0;
              }
              @include respond(medium) {
                margin: 0 0 5px 0;
              }
              @include respond(small) {
                margin: 0 0 5px 0;
              }
            }
          }
        }
      }
    }
  }

}