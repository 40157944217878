&.history-block {
  position: relative;
  padding: 0 0 115px 0;
  @include respond-down(medium) {
    padding: 36px 0 36px 0;
  }

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 37px;
    top: -35px;
    right: 0;
    left: 0;
    z-index: 11;
    background-image: url("../images/pages/index/blocks/line.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include respond(medium) {
      background-position: center center;
      background-size: 150% 100%;
    }
    @include respond(small) {
      display: none;
    }
  }

  &:after {
    height: 74px;
    top: inherit;
    bottom: -72px;
    background-image: url("../images/pages/index/blocks/line2.png");
    @include respond-down(middle) {
      background-size: contain;
    }
    @include respond(medium) {
      background-size: 150% 100%;
    }
  }

  .leafs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    pointer-events: none;

    .leaf {
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      &.first {
        width: 167px;
        height: 218px;
        left: 81px;
        bottom: 88px;
        background-image: url('../images/pages/index/blocks/history-block/leaf.png');
        @include respond(middle) {
          width: 105px;
          height: 165px;
          left: 3px;
          bottom: 120px;
        }
        @include respond(medium) {
          height: 143px;
          width: 110px;
          top: 105px;
          left: -32px;
        }
        @include respond(small) {
          width: 40px;
          height: 94px;
          left: 0;
          top: 0;
          background-image: url('../images/pages/index/blocks/history-block/leaf-1-small.png');
        }
      }

      &.second {
        width: 176px;
        height: 217px;
        top: 80px;
        right: 23px;
        background-image: url('../images/pages/index/blocks/history-block/leaf-2.png');

        @include respond(middle) {
          width: 127px;
          height: 164px;
          top: 80px;
          right: -50px;
        }
        @include respond(small) {
          width: 71px;
          height: 82px;
          right: 0;
          top: 0;
          background-image: url('../images/pages/index/blocks/history-block/leaf-2-small.png');
        }
      }

      &.third {
        width: 319px;
        height: 142px;
        right: 0;
        bottom: -29px;
        background-image: url('../images/pages/index/blocks/history-block/leaf-3.png');
        @include respond(middle) {
          width: 224px;
          height: 100px;
          bottom: 31px;
          right: -66px;
        }
      }

      &.fourth {
        width: 121px;
        height: 219px;
        top: 73px;
        right: 0;
        background-image: url('../images/pages/index/blocks/history-block/leaf-4.png');
      }
    }
  }

  .wrapper {
    padding: 126px 0 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond(middle) {
      padding: 124px 0 21px 0;
    }
    @include respond(medium) {
      padding: 47px 0 14px 0;
    }
    @include respond(small) {
      padding: 0 0 14px 0;
    }

    .block-title {
      .title {

      }

      .text {
        margin: 30px 0 0 0;
        font-size: 21px;
        color: $black;
        text-align: center;
        line-height: 30px;
        @include respond(middle) {
          margin: 15px 0 0 0;
        }
        @include respond-down(medium) {
          font-size: 16px;
          margin: 27px 0 0 0;
        }
        @include respond(small) {
          margin: 21px 0 0 0;
        }

        .item {
          display: none;

          &.active {
            display: block;
          }
        }

      }
    }

    .button {
      width: 234px;
      margin: 64px 0 20px 0;
      @include respond(middle) {
        margin: 58px 0 20px 0;
        font-size: 19px;
        width: 189px;
        height: 41px;
      }
      @include respond-down(medium) {
        margin: 45px 0 0 0;
        width: 175px;
        height: 41px;
        font-size: 16px;
      }
      @include respond(small) {
        margin: 27px 0 0 0;
      }
    }
  }

  .owl-carousel .owl-stage-outer {
    overflow: visible !important;
  }

  .news-videos-list-page {
    margin: 100px 0 0 0;
    padding: 0 104px;
    overflow: hidden;
    @include respond-down(middle) {
      padding: 0 52px;
    }
    @include respond(small) {
      padding: 0;
      margin: 50px -20px 20px;
    }

    .owl-nav {
      top: 44%;

      .owl-prev {
        left: -70px;
        @include respond-down(middle) {
          left: -50px;
        }
      }

      .owl-next {
        right: -70px;
        @include respond-down(middle) {
          right: -50px;
        }
      }
    }

    .item {
      margin: 0 30px;
      @include respond(medium) {
        margin: 0 15px;
      }
    }

    .button {
      max-width: 234px;
      margin-left: auto;
      margin-right: auto;
      @include respond-up(middle) {
        margin: 58px auto 0;
        height: 53px;
      }
      @include respond-down(medium){
        margin-top: 20px;
        width: 175px;
        height: 41px;
        font-size: 16px;
      }
    }
  }
}

