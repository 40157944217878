.recipes-view-page {
  position: relative;
  background-image: url('../images/pages/index/blocks/texture-1.jpg');
  background-repeat: repeat;
  z-index: 1;
  overflow-x: hidden;

  .breadcrumbs-block {
    @include respond-up(middle) {
      margin: 0 0 50px;
    }
    @include respond-down(medium) {
      opacity: 0;
    }
  }

  .page-title {
    margin: 0 0 30px;
    font-size: 50px;
    @include respond(middle) {
      font-size: 45px;
    }
    @include respond-down(medium) {
      margin: 0 0 30px;
      font-size: 40px;
      text-align: center;
    }
    @include respond-down(small) {
      font-size: 30px;

    }
  }

  .wrapper {
    padding-bottom: 50px;
  }
}

.recipe {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  @include respond-down(medium) {
    align-items: center;
  }

  @include respond-down(small) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @at-root &__image {
    display: flex;
    position: relative;
    width: 407px;
    height: 280px;
    justify-content: center;
    align-items: center;
    @include respond-up(middle) {
      margin-bottom: 30px;
    }

    @include respond-down(middle) {
      width: 100%;
      height: 100%;
    }


    @include respond(small) {
      width: 264px;
      height: 196px;
      margin: 0 auto;
      zoom: .9;
    }

  }
  @at-root &__plate {
    display: flex;
    width: 350px;
    height: 350px;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include respond-down(medium) {
      width: 272px;
      height: 272px;
    }

    @include respond-down(small) {
      width: 220px;
      height: 220px;
    }

    @at-root &--white {
      background-image: url('../images/pages/index/blocks/recipes-block/white-plate.png');
    }
    @at-root &--black {
      background-image: url('../images/pages/index/blocks/recipes-block/black-plate.png');
    }
  }
  @at-root &__food {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @at-root &__text {
    flex: 0 0 calc(100% - 272px);
    padding: 0 60px 0 40px;
    @include respond-down(medium) {
      display: flex;
      padding: 0 0 20px 27px;
    }

    @include respond-down(small) {
      margin: 27px 0 55px;
      padding: 0;
    }
  }
  @at-root &__info {
    display: flex;
    justify-content: space-between;
    @include respond-down(medium) {
      flex-wrap: wrap;
      & > div {
        display: flex;
        height: 80px;
        flex-direction: column;
        align-items: center;
        flex: 0 0 50%;

        &:nth-child(n+3) {
          margin: 30px 0 0;
          @include respond-down(small) {
            margin: 44px 0 0;
          }
        }
      }
    }

    @include respond-down(small) {
      margin: 0 auto;
    }

    @at-root &_title {
      margin: 0 0 10px;
      font-size: 20px;
      color: $black;
      text-align: center;
      @include respond-down(small) {
        font-size: 18px;
      }
    }
    @at-root &_icon {
      display: flex;
      position: relative;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @at-root &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    @at-root &_text {
      margin: 2px 0 0;
      font-size: 15px;
      color: #000000;
      text-align: center;

      @at-root &--large {
        font-size: 35px;
      }
    }
  }
  @at-root &__time {

  }
  @at-root &__difficulty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .recipe__info_title {
      @include respond-down(medium) {
        margin-bottom: auto;
      }
    }

    .recipe__info_icon {
      max-width: 74px;
      justify-content: flex-start;
      @include respond-down(medium) {
        max-width: 69px;
      }
    }

    @at-root &_bar {
      display: flex;
      position: relative;
      height: 12px;
      align-items: center;
      &:not(:last-child) {
        margin: 0 0 5px;
        @include respond-down(medium) {
          margin: 0 0 6px;
        }
      }

      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        border: 1px solid #000000;
      }

      &:after {
        content: "";
        display: block;
        width: 53px;
        height: 12px;
        border: 1px solid #000000;
      }

      @at-root &--active {
        &:before {
          background: #9B9B9B;
        }

        &:after {
          content: none;
        }
      }
      @at-root &-text {
        width: 20px;
        white-space: nowrap;
      }
    }
  }
  @at-root &__count {

  }
  @at-root &__description {
    margin: 36px 0 14px;
    @include respond-down(medium) {
      margin: 28px 0 35px;
    }

    @include respond-down(small) {
      margin: 0;
    }

    &, p {
      font-size: 20px;
      color: $black;
      line-height: 24px;

      @include respond-down(medium) {
        font-size: 18px;
      }
    }
  }
  @at-root &__share {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $black;
    @include respond-down(small) {
      flex-direction: column;
      align-items: flex-start;
    }

    @at-root &_icons {
      @include respond-up(medium) {
        margin-left: 8px;
      }

      @include respond-down(small) {
        margin-top: 14px;
        a {
          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
    }
  }

  @at-root &__portions {
    .recipe__info_icon {
      width: 82px;
      height: 53px;
    }

    .recipe__info_icon-text {
      left: 26px;
    }

  }
  @at-root &__recipe-text {
    margin: 70px 0 0;
    font-size: 20px;
    color: $black;
    line-height: 1;

    @include respond-down(medium) {
      font-size: 16px;
      line-height: 20px;
    }

    @include respond-down(small) {
      margin: 50px 0 0;
    }

    ol {
      list-style: none;
      counter-reset: li;

      li {
        display: flex;
        align-items: center;
        counter-increment: li;
        font-size: 20px;
        color: $black;
        line-height: 1;

        @include respond-down(medium) {
          font-size: 16px;
          line-height: 20px;
        }

        &:not(:last-child) {
          margin: 0 0 50px;
        }

        &::before {
          content: counter(li);
          display: inline-block;
          flex: 0 0 30px;
          font-family: $h-font;
          font-size: 40px;
          text-align: left;
          color: $red;
        }
      }
    }

    p {
      line-height: 1.25;
    }
  }
}

.ingredients {
  margin: 50px 0 0;
  @include respond-up(middle) {
    padding: 0 7%;
  }

  @at-root &__title {
    margin: 0 0 50px;
    text-align: center;
    .page-title {
      font-size: 40px;

      @include respond-down(middle) {
        font-size: 35px;
      }
      @include respond-down(medium) {
        font-size: 30px;
      }
      @include respond-down(small) {

      }
    }

    @include respond-down(medium) {
      margin: 0 0 90px;
    }

    @include respond-down(small) {
      margin: 0 0 20px;
    }
  }
  @at-root &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @at-root &_image {
      display: flex;
      @include respond-down(medium) {
        height: 100px;
      }

      img {
        max-height: 140px;
        height: 100%;
        width: 100%;
        object-fit: contain;
        @include respond-down(medium) {
          max-height: 110px;
        }
      }
    }
    @at-root &_title {
      max-width: 200px;
      margin: 50px 0 0;
      font-size: 20px;
      color: $black;
      text-align: center;
      @include respond-down(medium) {
        margin: 15px 0 0;
        font-size: 18px;
      }
    }
  }

  @at-root &__slider {
    .owl-prev {
      left: -70px;
      transform: translateY(-50%);
      @include respond-down(medium) {
        left: -20px;
      }
    }

    .owl-next {
      right: -70px;
      transform: translateY(-50%);
      @include respond-down(medium) {
        right: -20px;
      }
    }
  }
}