$max-row-width: 1280;
$grid-columns: 12;
$grid: (
        ssmall: (
                from: 0,
                to: 640,
                gutter: 10,
                spacer:20
        ),
        small: (
                from: 0,
                to: 767,
                gutter: 10,
                spacer: 40
        ),
        medium: (
                from: 768,
                to: 1279,
                gutter: 5,
                spacer: 58,
        ),
        middle: (
                from: 1280,
                to: 1400,
                gutter: 20,
                spacer: 65
        ),
        large: (
                from: 1401,
                gutter: 20,
                spacer: 20
        )
);
$body-font-size: 16px;
$font: "Proxima Nova", serif;
$h-font: "Forte_VR", serif;
$h-large-font: "Pacifico", serif;
$bold: bold;
$semibold: 600;
$extrabold: 800;

$t: transparent;

$red: #e6040c;
$black000: #000;
$black: #331b09;
$purple: #872c7f;
$white: #ffffff;
$black45: #454545;
$grey9B: #9B9B9B;
$darkorange: #4B2305;
$darkorange2: #422006;
$choco: #6B3302;

$primary-color: $red;
$alert-color: #e6040c;

$default-transition: transform .2s ease-out;