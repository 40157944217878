&.first-block {
  display: block;
  position: relative;
  width: 100%;

  .wrapper {
    min-height: 100%;

    .bg-image {
      @include respond-down(small) {
        display: block;
        position: absolute;
        height: 392px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 57% 50%;
        background-repeat: no-repeat;
        pointer-events: none;
        z-index: 1;
      }
    }

    .slide {
      background-position: center 77%;
      @include respond-property(height, 620px, 647px, 690px, 859px);
      @include respond(large) {
        min-height: 850px;
      }
      @include respond(middle) {
        background-position-y: 40%;
        background-position-x: 50%;
      }
      @include respond(medium) {
        background-position: 66% 35%;
      }
      @include respond(small) {
        background-image: none !important;
        height: 392px;
      }
    }

    .title-block {
      @include respond(large) {
        margin: 276px 0 0 0;
      }
      @include respond(middle) {
        margin: 212px 0 0 0;
      }
      @include respond(medium) {
        margin: 198px 0 0 0;
      }
      @include respond(small) {
        position: absolute;
        bottom: 75px;
      }

      .title {
        position: relative;
        font-family: $h-large-font;
        @include respond-property(font-size, 50px, 108px, 145px, 187px);
        color: $white;
        @include respond(small) {
          margin: 0 0 10px 0;
          z-index: 10;
        }
      }

      .subtitle {
        position: relative;
        font-family: $h-font;
        @include respond-property(font-size, 26px, 40px, 54px, 72px);
        color: $white;
        text-align: center;
        background-repeat: no-repeat;
        background-image: url('../images/pages/index/blocks/first-block/description-bg.png');
        background-size: 100% 100%;
        white-space: nowrap;

        @include respond(large) {
          margin-top: 5px;
          margin-left: -11px;
          line-height: 1.1;
          padding-right: 9px;
        }
        @include respond(middle) {
          margin: 6px 0 0 0;
          padding: 0 24px;
          background-image: url('../images/pages/index/blocks/first-block/description-bg-middle.png');
        }
        @include respond(medium) {
          width: 414px;
          margin: 7px 0 0 0;
          padding: 5px 0;
          background-size: 414px 50px;
          background-image: url('../images/pages/index/blocks/first-block/description-bg-medium.png');
        }
        @include respond(small) {
          margin: 0 0 20px 0;
          background: none;
          background-image: none;
          z-index: 10;
        }
      }

      .button {
        @include respond(large) {
          margin: 43px 0 0 -10px;
        }
        @include respond(middle) {
          width: 222px;
          height: 41px;
          margin: 40px 0 0 0;
          font-size: 18px;
        }
        @include respond(medium) {
          width: 250px;
          height: 36px;
          margin: 27px 0 0 0;
          font-size: 20px;
        }
        @include respond(small) {
          position: relative;
          width: 100%;
          height: 36px;
          font-size: 20px;
          z-index: 10;
        }
      }
    }

    .bottle {
      display: block;
      width: 460px;
      height: 620px;
      margin: 144px 0 0 -12px;
      background: url('../images/pages/index/blocks/first-block/bottle.png') no-repeat;
      background-size: contain;
      @include respond(middle) {
        margin: 112px 0 0 7px;
        width: 355px;
        height: 480px;
      }
      @include respond(medium) {
        width: 277px;
        height: 373px;
        margin: 162px 0 0 6px;
        background: url('../images/pages/index/blocks/first-block/bottle-medium.png') no-repeat;
      }
      @include respond(small) {
        width: 200px;
        height: 400px;
      }
    }

    .tomato {
      display: block;
      position: absolute;
      width: 144px;
      height: 142px;
      margin: 205px 0 0 73px;
      background: url('../images/pages/index/blocks/first-block/tomato.png') no-repeat center center;
      @include respond-down(middle) {
        width: 112px;
        height: 110px;
        margin: 162px 0 0 51px;
        background-size: contain;
      }
      @include respond(medium) {
        width: 105px;
        height: 105px;
        margin: 129px 0 0 69px;
      }
    }

    .purple-bg {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: $purple;
      width: 100%;
      height: 230px;
    }
  }

  .scroll-to-bottom {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    bottom: 78px;
    transform: translateX(-50%);

    .svg-icon {
      @include respond(middle) {
        width: 21px;
        height: 31px;
      }
    }

    .arrows {
      display: block;
      position: absolute;
      bottom: -5px;
      left: 4px;

      span {
        position: absolute;
        top: 0;
        width: 18px;
        height: 18px;
        border-left: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        transform: rotate(-45deg);
        animation: sdb07 2s infinite;
        opacity: 0;
        box-sizing: border-box;

        &:nth-of-type(1) {
          animation-delay: 0s;
        }

        &:nth-of-type(2) {
          top: 10px;
          animation-delay: .15s;
        }

        &:nth-of-type(3) {
          top: 22px;
          animation-delay: .3s;
        }

        @keyframes sdb07 {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
  }


  .owl-dots {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    z-index: 20;
    @include respond-down(small) {
      display: none;
    }

    .owl-dot {
      display: block;
      position: relative;
      width: 20px;
      height: 20px;
      background: rgba($white, .6);
      border: 2px solid $t;
      z-index: 15;
      transition: all ease-out .2s;
      box-sizing: border-box;

      &:not(:last-child) {
        margin-right: 7px;
      }

      span {

      }

      &.active {
        border: 2px solid $red;
      }
    }
  }
}