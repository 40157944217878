&.why-block {
  display: block;
  position: relative;
  background-image: url('../images/pages/index/blocks/texture-1.jpg');
  background-repeat: repeat;
  overflow-y: hidden;

  @include respond(large) {
    margin-top: -30px;
    padding: 132px 0 100px 0;
    }
  @include respond(middle) {
    padding: 108px 0 39px 0;
    transform: translateY(-29px);
    }
  @include respond(medium) {
    margin-top: -20px;
    padding: 81px 0 0 0;
    }
  @include respond(small) {
    padding: 35px 0 0 0;
    }

  .bg-images {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 10;
    overflow: hidden;

    .svg-icon {
      position: absolute;
      @include respond-down(middle) {
        transform: scale(.75);
        }
      &.icon-icon-apple {
        top: 759px;
        left: 201px;
        @include respond(middle) {
          top: 565px;
          left: 93px;
          }
        }
      &.icon-icon-apple-2 {
        top: 853px;
        right: 137px;
        @include respond(middle) {
          top: 636px;
          right: 30px;
          }
        }
      &.icon-icon-beans {
        top: 370px;
        right: 233px;
        @include respond(middle) {
          top: 275px;
          right: 90px;
          }
        }
      &.icon-icon-cutted-tomato {
        top: 591px;
        right: 283px;
        @include respond(middle) {
          top: 440px;
          right: 140px;
          }
        }
      &.egg-1 {
        bottom: 564px;
        left: 39px;

        @include respond-down(middle) {
          display: none;
          }
        }
      &.egg-2 {
        top: 176px;
        right: 261px;

        @include respond(middle) {
          top: 131px;
          right: 113px;
          }
        }
      &.icon-icon-garlic {
        top: 175px;
        left: 304px;
        @include respond(middle) {
          top: 130px;
          left: 160px;
          }
        }
      &.icon-icon-pepper {
        top: 658px;
        left: 13px;
        @include respond-down(middle) {
          display: none;
          }
        }
      &.icon-icon-spinach {
        top: 405px;
        left: 169px;
        @include respond(middle) {
          top: 302px;
          left: 65px;
          }
        }
      &.icon-icon-strawberry {
        top: 118px;
        left: -41px;
        @include respond(middle) {
          top: 87px;
          left: -45px;
          }
        }
      &.tomato-1 {
        bottom: 564px;
        left: 314px;

        @include respond(middle) {
          left: 92px;
          bottom: 631px;
          }
        }
      &.tomato-2 {
        top: 552px;
        right: 124px;
        @include respond(middle) {
          top: 411px;
          right: 11px;
          }
        }

      }
    }
  .block-title {
    .title {
      margin: 0 0 82px 0;
      @include respond(middle) {
        margin: 0 0 48px 0;
        }
      @include respond(medium) {
        margin: 0 0 69px 0;
        }
      @include respond(small) {
        margin: 0 0 36px 0;
        }
      }
    }
  .block-content.why {
    position: relative;
    z-index: 11;
    @include respond(large) {
      margin: 0 -28px;
      }
    @include respond(middle) {
      padding: 0 37px;
      }
    .items {
      display: flex;
      width: 100%;
      flex-direction: column;

      .item-row {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        @include respond(large) {
          &:not(:first-child) {
            margin: 48px 0 0 0;
            }
          }
        @include respond(middle) {
          &:not(:first-child) {
            margin: 27px 0 0 0;
            }
          }
        @include respond(medium) {
          &:not(:first-child) {
            margin: 52px 0 0 0;
            }
          }
        @include respond(small) {
          flex-direction: column;
          align-items: center;
          &:not(:last-child) {
            margin: 0 0 36px 0;
            }
          }
        .item {
          display: flex;
          width: 20%;
          flex-direction: column;
          align-items: center;
          @include respond-up(middle) {
            min-width: 233px;
            }
          @include respond(small) {
            margin: 0 0 36px 0;
            }
          .item-icon {
            display: flex;
            width: 140px;
            height: 140px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $white;
            background-image: url("../svg/why-item-border.svg");
            background-repeat: no-repeat;
            background-position: center center;
            @include respond(middle) {
              width: 104px;
              height: 104px;
              background-size: 93%;
              }
            @include respond(medium) {
              width: 105px;
              height: 105px;
              background-size: 93%;
              }
            @include respond(small) {
              width: 104px;
              height: 104px;
              background-size: 93%;
              }
            .svg-icon {
              transform-origin: center center;
              @include respond-down(middle) {
                transform: scale(.75);
                }
              }
            }
          .item-title {
            margin: 24px 0 0 0;
            text-align: center;
            max-width: 260px;
            font-size: 21px;
            line-height: 25px;

            @include respond(middle) {
              margin: 19px 0 0 0;
              }
            @include respond-down(medium) {
              font-size: 16px;
              line-height: 20px;
              }
            @include respond(small) {
              margin: 15px 0 0 0;
              }
            }
          }
        }
      }
    }
  @import "video-block";
  }
