body.news-videos-list-page {
  position: relative;
  background-image: url('../images/parts/texture-900.jpg');
  background-size: 912px 937px;
  background-repeat: repeat;
  z-index: 1;
  overflow-x: hidden;

  .bg-images {
    display: block;
    pointer-events: none;

    & > * {
      display: block;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      top: 300px;
    }

    .news__left {
      left: 0;
      width: 263px;
      height: 1085px;
      background-image: url('../images/pages/news/news__bg-left.png');
    }

    .news__right {
      right: 0;
      width: 258px;
      height: 1187px;
      background-image: url('../images/pages/news/news__bg-right.png');
    }
  }
}

.news-videos-list-page {
  .wrapper {
    .breadcrumbs-block {
      @include respond-down(medium) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .items-list {
    display: block;
    margin: 60px 0 0;
    @include respond(small) {
      margin: 30px 0 0;
    }

    &.videos {
      .items {
        .item {
          position: relative;
          display: none;

          &:hover {
            .play-button {
              transform: translate(-50%, -50%) scale(.74);
            }
          }

          .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(.7);
            transition: all ease-out .15s;
          }

          &.active {
            display: block;
          }

          .video-wrapper {
            &,
            & > *,
            .plyr__video-wrapper {
              object-fit: cover;
              height: 225px;
            }
          }

          .title {
            margin: 15px 0 0;
            @include respond(small) {
              margin: 8px 0 0;
            }
          }
        }
      }
    }

    .year {
      font-weight: $bold;
      font-size: 35px;
      color: $red;
      text-align: center;
    }

    .items {
      display: grid;
      margin: 60px 0;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 60px;
      grid-row-gap: 50px;

      &.index-page {
        display: block;
        margin: 0;
        grid-template: initial;

        .item {
          background: #F0EFEA;
        }

        .owl-item {
          opacity: 0;
          pointer-events: none;
          transition: all ease-out .2s;

          &.active {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      @include respond-down(medium) {
        grid-template-columns: 1fr;
      }
      @include respond(small) {
        margin: 37px 0;

      }

      .item {
        display: flex;
        padding: 26px 34px;
        background: $white;
        position: relative;
        border-radius: 10px;
        flex-direction: column;

        @include respond-down(medium) {
          margin: 0 auto;
        }
        @include respond(medium) {
          max-width: 490px;
        }
        @include respond(small) {
          max-width: 250px;
          padding: 14px 10px;
        }

        .date {
          display: flex;
          position: absolute;
          height: 83px;
          width: 83px;
          top: -15px;
          left: -15px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: $red;
          border-radius: 10px;
          text-align: center;
          color: $white;
          font-weight: $bold;
          @include respond(small) {
            width: 54px;
            height: 57px;
          }

          .day {
            font-size: 35px;

            @include respond(small) {
              font-size: 25px;
            }
          }

          .month {
            font-size: 18px;

            @include respond(small) {
              font-size: 12px;
            }
          }
        }

        .image {
          display: flex;
          margin: 0 0 20px 0;

          &, & > img {
            width: 100%;
            @include respond(small) {
            }
          }

          img {
            object-fit: contain;
          }
        }

        .title {
          font-weight: $bold;
          font-size: 25px;
          color: $red;
          line-height: calc(32 / 25);
          @include respond(small) {
            font-size: 16px;
            line-height: 1.25;
          }
        }

        .description {
          font-size: 17px;
          color: $black;
          line-height: calc(19 / 17);

          @include respond(small) {
            font-size: 12px;
            line-height: calc(15 / 12);
          }
        }
      }
    }
  }

  .page-title {
    font-weight: $bold;
    font-family: $font;
    font-size: 35px;
    color: $red;
    text-align: center;
  }

  .filter-items {
    display: flex;
    margin: 55px auto 0;
    justify-content: center;
    flex-wrap: wrap;
    @include respond(small) {
      max-width: 400px;
    }

    .item {
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba($red, .11);
      border: 2px solid #B80202;
      border-radius: 25px;
      padding: 14px 26px;

      @include respond(small) {
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &:nth-child(n+3) {
          margin-top: 16px !important;
        }
      }

      &, & > .link {
        transition: all ease-out .2s;
        cursor: pointer;
      }

      &.active, &:hover {
        background: #E3000F;
        border-color: $t;

        .link {
          color: $white;
        }
      }

      &:not(:last-child) {
        margin: 0 20px 0 0;
      }

      .link {
        font-weight: $bold;
        font-size: 18px;
        color: #B4110B;
        text-align: left;
        @include respond(small) {
          font-size: 15px;
        }
      }
    }
  }

  .show-more {
    opacity: 0;
    pointer-events: none;
    @include borderedButton();

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}