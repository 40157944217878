&.recipes-block {
  display: block;
  position: relative;
  padding: 175px 0 55px 0;
  background-image: url('../images/pages/index/blocks/texture-1.jpg');
  background-repeat: repeat;

  &.no-bg {
    background: none;
  }

  @include respond(middle) {
    padding: 128px 0 64px;
  }
  @include respond(medium) {
    padding: 158px 0 52px 0;
  }
  @include respond(small) {
    padding: 60px 0 0 0;
  }

  .food-bg {
    .food {
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      &.potato {
        width: 187px;
        height: 170px;
        bottom: 126px;
        left: 100px;
        background-image: url('../images/pages/index/blocks/recipes-block/potato.png');
      }

      &.parsley {
        width: 199px;
        height: 195px;
        bottom: 123px;
        left: 444px;
        background-image: url('../images/pages/index/blocks/recipes-block/parsley.png');
      }

      &.pepper {
        width: 126px;
        height: 156px;
        top: 286px;
        right: 387px;
        background-image: url('../images/pages/index/blocks/recipes-block/pepper.png');
      }

      &.cutted-vegetables {
        width: 294px;
        height: 339px;
        right: 9px;
        bottom: 55px;
        background-image: url('../images/pages/index/blocks/recipes-block/cutted-vegetables.png');
      }

      &.carrot {
        width: 54px;
        height: 55px;
        top: 306px;
        right: 206px;
        background-image: url('../images/pages/index/blocks/recipes-block/carrot.png');
      }

      &.basil {
        width: 131px;
        height: 156px;
        top: 307px;
        left: 265px;
        background-image: url('../images/pages/index/blocks/recipes-block/basil.png');
      }
    }
  }

  .block-title {
    .title {
      @include respond-up(medium) {
        white-space: nowrap;
      }
    }
  }

  .block-content {
    margin: 75px 0 0 0;
    @include respond(middle) {
      margin: 34px 0 0 0;
    }
    @include respond(medium) {
      margin: 48px 0 0 0;
    }
    @include respond(small) {
      margin: 25px 0 0 0;
    }

    &.slider {
      display: block;
      width: 100%;

      .recipes {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        @include respond(small) {
          &.hidden {
            display: none;
          }
        }

        .recipe-row {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          @include respond(small) {
            align-items: center;
            flex-direction: column;
          }

          &.one-item {
            .recipe-item {
              margin: initial !important;
            }
          }

          &.two-items {
            .recipe-item {
              margin: 0 auto !important;
            }
          }

          &.bottom {
            margin-top: 55px;
            @include respond(middle) {
              margin-top: 18px;
              margin-left: 208px;
            }
            @include respond(medium) {
              margin-top: 77px;
              margin-left: 0;
            }
            @include respond(small) {
              margin-left: 0;
            }

            .recipe-item {
              &:nth-child(1) {
                @include respond-up(middle) {

                }
                @include respond-down(medium) {

                }
              }
            }
          }

          .recipe-item {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            overflow: hidden;
            align-items: center;

            @include respond(small) {
              align-items: center;
              margin: 0 0 50px 0;
            }

            &:nth-child(1) {
              @include respond-up(middle) {

              }
              @include respond(medium) {
                margin-right: 20px;
              }
              @include respond(small) {

              }
            }

            &:nth-child(2) {
              @include respond(medium) {

              }
              @include respond(small) {

              }
            }

            &.hidden {
              display: none;
            }

            .image-wrapper {
              display: flex;
              position: relative;
              width: 407px;
              height: 280px;
              justify-content: center;
              align-items: center;

              @include respond-down(middle) {
                width: 325px;
                height: 224px;
              }
              @include respond(small) {
                width: 284px;
                height: 196px;
              }

              .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .title {
              margin: 21px 0 0 0;
              text-align: center;
              font-family: $h-font;
              @include respond-property(font-size, 20px, 20px, 20px, 21px);
              font-size: 21px;
              color: $black;
              @include respond(middle) {
                margin: 32px 0 0 0;
              }
              @include respond(medium) {
                margin: 30px 0 0 0;
              }
            }
          }

          .row {
            width: 100%;
          }
        }
      }

      .owl-nav {
        @include respond(medium) {
          top: 42%;
        }

        .owl-prev {
          @include respond(large) {
            top: 47px;
            left: 85px;
          }
          @include respond(middle) {
            left: 155px;
          }
          @include respond(medium) {
            left: 40px;
          }
        }

        .owl-next {
          @include respond(large) {
            top: 47px;
            right: 85px;
          }
          @include respond(middle) {
            right: 155px;
          }
          @include respond(medium) {
            right: 36px;
          }
        }
      }

      .more-recipes {
        margin: 0 auto;
      }
    }
  }

  .wrapper {
    padding-bottom: 100px;
  }

  &.product-page {
    .block-content.slider {
      .recipes {
        .recipe-row {
          .recipe-item {
            .title {
              font-family: $font;
              color: $black;
              @include respond-property(font-size, 20px, 20px, 20px, 20px);
            }
          }
        }
      }
    }
  }
}