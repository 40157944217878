.product__item {
  display: flex;
  position: relative;
  padding: 22px 26px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  background: $white;
  box-shadow: 0 2px 30px 0 rgba($black000, .24);
  @include respond-up(middle) {
    max-width: 280px;
  }
  @include respond-property(height, 430px, 430px, 439px, 439px);
  > a.product_link {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20px;
  }
  .button {
    width: 100%;
    margin-top: auto;;
  }
  &:hover {
    .image-wrapper {
      .image {

        transform: scale(1.1) translateZ(0);
      }
    }
  }

  .title {
    display: block;
    margin-bottom: 15px;;  
    font-size: 18px !important;
    color: $black000;
    line-height: calc(24 / 20);
    text-align: center;

    @include respond(small) {
      margin: 0 -10px 35px;
      font-size: 16px !important;
    }
  }

  .image-wrapper {
    display: inline-block;
    position: relative;
    // top: 50%;
    // left: 50%;
    width: 100%;
    height: 196px;
    margin: 0 auto;
    // transform: translate(-50%, -50%);
    margin-top: auto;
    @include respond(small) {
      height: 165px;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      will-change: transform;
      transition: all ease-out .5s;
    }
  }

  .description {
    display: flex;
    width: 100%;
    position: relative;
    right: 0;
    // bottom: 23px;
    // left: 0;
    margin-top: 30px;
    justify-content: center;
    flex-direction: row;

    .grammage, .expiration {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 15px;
      white-space: nowrap;
      color: $grey9B;
      @include respond(small) {
        font-size: 14px;
      }
    }

    .grammage {
      margin: 0 40px 0 0;

      .icon {
        display: block;
        margin: -1px 4px 0 0;
        width: 13px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0C5.27425088 0 4.19453292.61843114 3.55776515 1.55555556H1.18181818C.53390488 1.55555556 0 2.0826155 0 2.72222222V12.8333333C0 13.4729401.53390488 14 1.18181818 14H11.8181818C12.4660951 14 13 13.4729401 13 12.8333333V2.72222222c0-.63960672-.5339049-1.16666666-1.1818182-1.16666666h-.7878788c-.1420687-.00198345-.2742138.0717015-.3458345.19283944-.0716207.12113793-.0716207.27096095 0 .39209889s.2037658.19482289.3458345.19283944h.7878788c.2250564 0 .3939394.16671784.3939394.38888889V12.8333333c0 .2221711-.168883.3888889-.3939394.3888889H1.18181818c-.2250564 0-.3939394-.1667178-.3939394-.3888889V2.72222222c0-.22217105.168883-.38888889.3939394-.38888889h1.98200758C3.03218205 2.69885467 2.95454545 3.09061511 2.95454545 3.5 2.95454545 5.42839053 4.54656544 7 6.5 7c1.95343436 0 3.5454545-1.57160947 3.5454545-3.5C10.0454545 1.57160967 8.45343436 0 6.5 0zm0 .77777778c1.52763315 0 2.75757576 1.21417411 2.75757576 2.72222222 0 1.50804811-1.22994261 2.72222222-2.75757576 2.72222222S3.74242424 5.00804811 3.74242424 3.5C3.74242424 1.99195189 4.97236685.77777778 6.5.77777778zM5.2750947 1.93836806c-.15334263.01473218-.28391661.11635034-.33422264.2601061s-.01111261.30326896.10032112.40829667l.70170455.69270834c-.01724135.06397066-.03077652.13119069-.03077652.20052083 0 .42955481.35274535.77777778.78787879.77777778.43513344 0 .78787879-.34822297.78787879-.77777778 0-.42955481-.35274535-.77777778-.78787879-.77777778-.07023053 0-.13832355.01336164-.203125.03038195l-.70170455-.69270834c-.07370191-.07685027-.17594465-.12073371-.28314393-.12152777a.39908822.39908822 0 0 0-.03693182 0z' fill='%239B9B9B' fill-rule='nonzero'/%3E%3C/svg%3E");
      }
    }

    .expiration {

      .icon {
        display: block;
        margin: 0 4px 0 0;
        width: 13px;
        height: 13px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.60955 8.00561667L6.22418333 6.87223333C6.032 6.79683333 5.85 6.65903333 5.85 6.5l.00303333-.04051667c-.00541666-.05416666.00108334-.11396666.01343334-.17441666l.2002-2.60173334C6.06666667 3.44413333 6.2608 3.25 6.5 3.25c.2392 0 .43333333.19413333.43333333.43333333l.18113334 2.35538334 1.10781666 1.35416666c.16921667.16921667.16921667.44351667 0 .61273334-.169.16921666-.44351666.16921666-.61273333 0zM13 6.5c0 3.5897333-2.9102667 6.5-6.5 6.5C2.91026667 13 0 10.0897333 0 6.5 0 2.91005 2.91026667 0 6.5 0c.55596667 0 1.09265.078 1.60745.21016667.0429.00368333.08601667.01191666.12848333.02925C8.45715.33085 8.56223333.58435 8.4708.80535c-.08081667.195-.2873.29921667-.48641667.2587l-.00195.00606667C7.50923333.9412 7.01393333.86645 6.5.86645 3.38888333.86645.86666667 3.38866667.86666667 6.49978333.86666667 9.6109 3.38888333 12.1331167 6.5 12.1331167S12.1333333 9.6109 12.1333333 6.49978333c0-.2873-.0283833-.56723333-.0697666-.84305l.0106166-.00151666C12.0644333 5.6212 12.0577167 5.5861 12.05685 5.54905c-.0054167-.2392.1841667-.43766667.4235833-.44308333.2392-.00541667.4376667.18416666.4430834.42358333 0 .00606667-.0015167.0117-.0017334.01755C12.96815 5.8591 13 6.17543333 13 6.5zm-.6346167-2.15128333c-.1692166.16921666-.4437333.16921666-.61295 0l-.9191-.91931667-.91909997.91931667c-.16921666.16921666-.44373333.16921666-.61273333 0-.16921667-.16921667-.16921667-.44351667 0-.61273334l.9191-.91931666L9.3015 1.89735c-.16921667-.16921667-.16921667-.44351667 0-.61295.169-.16921667.44351667-.16921667.61273333 0l.91909997.91931667.9191-.91931667c.1692167-.16921667.4437334-.16921667.61295 0 .169.16921667.169.44351667 0 .61295l-.9193166.91931667.9193166.91931666c.1692167.169.1692167.44351667 0 .61273334z' fill='%239B9B9B' fill-rule='nonzero'/%3E%3C/svg%3E");
      }
    }
  }
}