body.about-page {
  position: relative;

  .bg-images {
    display: block;
    pointer-events: none;

    & > * {
      display: block;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      top: 300px;
    }

    .left {
      left: 0;
      width: 263px;
      height: 1123px;
      background-image: url('../images/pages/news/news__view-left.png');
    }

    .right {
      right: 0;
      width: 267px;
      height: 1019px;
      background-image: url('../images/pages/news/news__view-right.png');
    }
  }
}

.about-page {
  position: relative;
  background-image: url('../images/parts/texture-900.jpg');
  background-size: 912px 937px;
  background-repeat: repeat;
  z-index: 100;

  .wrapper {

  }

  .breadcrumbs-list {
    opacity: 0;
  }

  @include respond-down(medium) {
    background: $white;
    .row {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @include respond-down(small) {
    .row {
      padding-left: 20px;
      padding-right: 20px;
    }
  }


  .dates {
    display: grid;
    position: relative;
    padding: 0 50px;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 75px;

    @include respond-down(medium) {
      margin: 0;
      grid-template-columns: 1fr;
    }
    @include respond-down(small) {
      padding: 0;
    }
    @at-root &__item {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      @include respond-down(small) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      @at-root &_image {
        display: block;
        width: 131px;
        height: 131px;
        flex: 0 0 131px;
        background-color: $t;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @at-root &--povidlo {
          background-image: url('../images/pages/about/povidlo.png');
        }
        @at-root &--fryma {
          background-image: url('../images/pages/about/fryma.png');
        }
        @at-root &--sauce {
          background-image: url('../images/pages/about/sauce.png');
        }
        @at-root &--jam {
          background-image: url('../images/pages/about/jam.png');
        }
        @at-root &--water {
          background-image: url('../images/pages/about/water.png');
        }
      }
      @at-root &_text {
        margin-left: 67px;
        @include respond-down(medium) {
          margin-right: auto;
        }

        @include respond-down(small) {
          margin-left: 0;
        }
      }
      @at-root &_date {
        font-weight: $bold;
        font-size: 50px;
        color: $red;
        @include respond-down(small) {
          margin: 0 0 15px;
        }
      }
      @at-root &_description {
        margin: 25px 0 0;
        font-weight: $semibold;
        font-size: 16px;
        color: $black;
        line-height: calc(25 / 16);
      }
    }
  }
}

.about__title {
  font-family: $font;
  text-align: center;
  font-weight: $bold;
  font-size: 60px;
  line-height: calc(75 / 60);
  white-space: nowrap;
  @include respond-down(medium) {
    font-size: 35px;
  }
}

.about__wrapper {
  position: relative;
  background: $white;
  border-radius: 10px;
  @include respond-property(padding-bottom, 75px, 148px, 200px, 226px);
  @include respond-up(middle) {
    padding-left: 64px;
    padding-right: 64px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100px;
      top: -100px;
      right: 0;
      left: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: $white;
    }
  }

}

.about__image {
  display: block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @at-root &--first {
    width: 100%;
    margin: 20px 0 0;
    height: 424px;
    background-image: url('../images/pages/about/first.jpg');
    @include respond-down(medium) {
      width: 100%;
      height: 297px;
    }

    @include respond-down(small) {
      height: 119px;
    }
  }
}

.about__info {
  display: grid;
  margin: 50px 0 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 100px;
  align-items: center;
  @include respond(medium) {
    margin-top: 90px;
    grid-row-gap: 80px;
  }
  @include respond-down(small) {
    margin-top: 54px;
    grid-row-gap: 52px;
    grid-template-columns: 1fr;
  }
  @at-root &_item {
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @at-root &_title {
    font-size: 60px;
    color: #000;
    @include respond-down(medium) {
      font-size: 45px;
    }
  }
  @at-root &_subtitle {
    margin: 6px 0 0;
    font-weight: 300;
    font-size: 15px;
    color: #000;
    text-align: center;
    line-height: calc(25 / 14);
    @include respond-down(medium) {
      margin: 0;
    }
  }
  @at-root &_description {
    max-width: 280px;
    margin: 25px 0 0;
    font-weight: 300;
    font-size: 16px;
    color: #000;
    text-align: center;
    line-height: calc(25 / 16);
    @include respond-down(medium) {
      margin: 12px 0 0 0;
      font-size: 13px;
      line-height: calc(25 / 13);
    }
  }
  @at-root &_icon {
    display: block;
    width: 125px;
    height: 125px;
    background-size: contain;
    background-repeat: no-repeat;
    @include respond-down(medium) {
      width: 80px;
      height: 80px;
    }

    @at-root &--auto {
      background-image: url('../images/pages/about/auto.png');
    }
    @at-root &--sto {
      background-image: url('../images/pages/about/sto.png');
    }
    @at-root &--glass {
      background-image: url('../images/pages/about/glass.png');
    }
  }
}

.import {
  display: flex;
  margin: 70px 0;
  padding: 75px 85px 75px 42px;
  justify-content: space-between;
  align-items: center;
  background: #F2F4F5;

  @include respond-down(medium) {
    padding: 60px 23px;
    justify-content: center;
  }
  @include respond-down(small) {
    flex-direction: column;
  }
  @at-root &__image {
    display: block;
    width: 493px;
    height: 350px;
    background: url('../images/pages/about/import.jpg') no-repeat center center;
    background-size: contain;
    @include respond(medium) {
      width: 348px;
      height: 260px;
      flex: 0 0 348px;
    }

    @include respond-down(small) {
      width: 100%;
      height: 207px;
    }
  }
  @at-root &__text {
    display: flex;
    padding: 0 0 0 20px;
    max-width: 370px;
    flex-direction: column;
    @include respond-down(small) {
      padding: 0;
    }
  }
  @at-root &__title {
    font-weight: $bold;
    font-size: 30px;
    color: $black;
    line-height: calc(35 / 30);
    @include respond-down(small) {
      font-size: 25px;
      line-height: calc(35 / 25);
    }

  }
  @at-root &__description {
    margin: 18px 0 0;
    font-weight: 200;
    font-size: 16px;
    color: $black;
    line-height: calc(25 / 16);
    @include respond-down(medium) {
      margin: 35px 0 0 0;
    }

    @include respond-down(small) {
      font-size: 16px;
      line-height: calc(25 / 16);
    }
  }
}

.fresh {
  @at-root &__title {
    max-width: 483px;
    font-size: 22px;
    color: #1D1F1B;
    line-height: calc(35 / 22);
    @include respond-down(medium) {
      max-width: 319px;
      font-size: 19px;
      line-height: calc(30 / 19);
    }
  }

  @at-root &__image {
    display: block;
    margin: 42px auto 0;
    width: 816px;
    height: 369px;
    background: url('../images/pages/about/fresh.jpg') no-repeat center center;
    background-size: contain;
    @include respond-down(medium) {
      width: 720px;
      height: 326px;
    }

    @include respond-down(small) {
      width: 100%;
    }
  }
}

.partners {
  margin: 110px 0 0;

  .owl-prev {
    left: 0;
  }

  .owl-next {
    right: 0;
  }

  @at-root &__title-block {
    display: flex;
    justify-content: space-between;
    @include respond-down(small) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  @at-root &__title {
    font-weight: $bold;
    font-size: 30px;
    color: $black;
    @include respond-down(small) {

    }
  }
  @at-root &__description {
    max-width: 500px;
    font-weight: 200;
    font-size: 16px;
    color: $black;
    line-height: calc(25 / 16);
    @include respond-down(medium) {
      max-width: 350px;
    }

    @include respond-down(small) {
      margin-top: 30px;
    }
  }
  @at-root &__slider {
    display: block;
    margin: 50px 0 0;
  }
  @at-root &__slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;

    @at-root &_image {
      max-width: initial;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.history {
  display: flex;
  position: relative;
  padding: 60px 107px;
  margin: 150px -65px 0;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 1966px;
    z-index: 0;
    background: url('../images/pages/about/history-bg.jpg') no-repeat top center;
    background-size: 100% 1966px;
  }

  @include respond-down(medium) {
    padding: 90px 24px 0;
    margin: 94px 0 0;
    &:before {
      height: 2146px;
      background-size: 100% 2146px;
    }
  }
  @include respond-down(small) {
    padding: 0;
    &:before {
      content: none;
      display: none;
    }
  }
  @at-root  &__title {
    position: relative;
    font-weight: $semibold;
    font-size: 30px;
    color: $black;
    text-align: center;
    @include respond-down(small) {
      font-size: 25px;
    }
  }
  @at-root &__first {
    display: flex;
    position: relative;
    margin: 60px 0 0 0;
    justify-content: space-between;
    align-items: flex-end;
    @include respond-up(middle) {
      padding: 0 0 0 118px;
    }

    @include respond-down(small) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  @at-root &__start {
    max-width: 500px;
    @include respond-down(medium) {
      padding-left: 27px;
    }

    @include respond-down(small) {
      margin-top: 35px;
      padding-left: 0;
    }

    @at-root &_title {
      position: relative;
      font-weight: $semibold;
      font-size: 18px;
      color: $black;
      line-height: calc(25 / 18);
    }
    @at-root &_description {
      margin: 23px 0 0;
      @include respond(medium) {
        margin: 0;
      }

      @include respond-down(small) {
        margin-top: 25px;
      }

      p {
        &:not(:last-child) {
          margin-bottom: 23px;
        }

        font-size: 15px;
        color: $black;
        line-height: calc(25 / 15);
      }
    }
  }

  @at-root &__founder {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-down(medium) {
      margin-bottom: auto;
    }

    @at-root &_year {
      margin: 0 0 40px;
      font-weight: $bold;
      font-size: 50px;
      color: #E83B38;
      text-align: center;
      @include respond-down(small) {
        margin: 0 0 10px;
      }
    }
    @at-root &_image {
      display: flex;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      overflow: hidden;
      background-image: url('../images/pages/about/founder.jpg');
      @include respond-down(medium) {
        width: 223px;
        height: 223px;
        background-size: contain;
        background-position: center center;
      }
    }
  }
  @at-root &__fact {
    display: flex;
    position: relative;
    width: 100%;
    height: 350px;
    margin: 110px 0 80px;
    padding: 27px 107px;
    justify-content: space-between;
    border: 15px solid #C4C2BE;
    border-radius: 25px;
    @include respond-down(medium) {
      padding: 44px 28px 0 50px;
      margin-bottom: 90px;
    }

    @include respond-down(small) {
      flex-direction: column;
      height: auto;
      border: 0;
      padding: 0;
    }

    @at-root &_item {
      @include respond-down(small) {
        &:not(:last-child) {
          margin-bottom: 65px;
        }
      }
    }
    @at-root &_image {
      display: block;
      margin: 0 0 10px;
      background-repeat: no-repeat;
      background-size: contain;
      @include respond-down(small) {
        margin: 0 auto;
      }

      @at-root &--jar {
        position: absolute;
        top: -50px;
        width: 215px;
        height: 269px;
        background-image: url('../images/pages/about/jar.png');
        @include respond-down(small) {
          position: initial;
        }
      }
      @at-root &--5300 {
        width: 252px;
        height: 146px;
        background-image: url('../images/pages/about/5300.png');
      }
    }

    @at-root &_title {
      max-width: 286px;
      font-weight: $bold;
      font-size: 15px;
      color: $black;
      text-align: center;
      line-height: 25px;

      @include respond-down(small) {
        margin-top: 10px;
        text-align: left;
      }

      @at-root &--first {
        margin-top: 160px;
        @include respond-down(small) {
          margin-top: -15px;

        }
      }
    }
  }
}