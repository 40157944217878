body.promo-page {
  position: relative;
  background-image: url('../images/parts/texture-900.jpg');
  background-size: 912px 937px;
  background-repeat: repeat;
  z-index: 1;
  overflow-x: hidden;

  .owl-prev {
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
  }

  .owl-next {
    right: -45px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.promo-page {
  .breadcrumbs-block {
    @include respond-down(medium) {
      opacity: 0;
    }
  }

  .text-block {
    text-align: center;

    .page-title {
      font-family: $font;
      font-size: 50px;
      color: $red;
      font-weight: $bold;

      @include respond-down(medium) {
        font-size: 35px;
      }
    }

    .page-description {
      font-size: 22px;
      color: #000000;

      @include respond(small) {
        margin: 0 0 22px;
        font-size: 15px;
        text-align: center;

      }
    }
  }

  .shops-select {
    display: flex;
    padding: 0 13px;
    align-items: center;
    height: 40px;
    background: $white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20);
    font-size: 15px;
    font-weight: 500;
    color: $red;

    &.active {
      &:before {
        transform: rotateZ(180deg);
      }
    }

    @include respond(small) {
      position: relative;
      z-index: 11;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 14px;
        height: 8px;
        top: 50%;
        right: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.85 7.484L.565 1.435 6.85 7.484zm6.36-6.049L6.923 7.484l6.285-6.049z' stroke='%23E6040C' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-top: -4px;
        transform: rotateZ(0);
        transition: transform ease-out .2s;
      }
    }
  }

  .shops-list {
    display: block;
    position: relative;
    margin: 25px 0 0 0;
    height: 100px;
    background: $white;
    border-radius: 7px;

    @include respond(medium) {
      height: 75px;
    }

    @include respond(small) {
      position: absolute;
      margin: 0;
      max-height: 0;
      left: 40px;
      right: 40px;
      overflow: scroll;
      height: auto;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      transition: all ease-out .2s;
      z-index: 10;

      &.active {
        max-height: 380px;
      }
    }

    .item {
      opacity: .35;
      display: flex !important;
      height: 105px !important;
      position: relative;
      justify-content: center;
      align-items: center;
      color: $black;
      cursor: pointer;

      @include respond(medium) {
        height: 75px !important;
      }
      @include respond(small) {
        height: auto !important;
        min-height: 40px;
      }
      @include respond(small) {
        opacity: 1;
        &:not(:last-child) {
          margin: 10px 0;
        }
      }

      &:before {
        display: block;
        position: absolute;
        width: 100%;
        height: 105px;
        background-size: 100% 105px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../svg/promo__shops-current.svg');
        bottom: 0;
        z-index: 5;
        transform: scaleX(1.18);

        @include respond(middle) {
          transform: scaleX(1.088);
        }
        @include respond(medium) {
          height: 75px;
          background-size: 100% 75px;
          transform: scaleX(1.29);
        }
        @include respond(small) {
          content: none !important;
          transform: initial;
        }
      }


      &:hover, &.active {
        opacity: 1;
        color: $white;
        @include respond(small) {
          color: $red;
        }

        &:before {
          content: "";
        }
      }

      &.all-shops {

      }

      span, img {
        display: block;
        position: relative;
        font-weight: $bold;
        font-size: 20px;
        text-align: center;
        line-height: 1;
        z-index: 6;

        @include respond(medium) {
          font-size: 15px;
          line-height: calc(17 / 15);
        }
        @include respond(small) {
          font-size: 15px;
          line-height: calc(18 / 15);
          font-weight: 400;
        }
      }

      img {
        width: 100%;
        height: 64px;
        max-width: 111px;
        object-fit: contain;
      }
    }
  }

  .promos {
    margin: 25px 0 0 0;

    .item {
      display: flex;
      padding: 48px 47px 40px;
      flex-direction: column;
      align-items: flex-start;
      background: $white;
      border-radius: 7px;
      @include respond(small) {
        padding: 17px 17px 30px;
      }

      .image {
        height: 225px;
        @include respond(small) {
          height: 140px;
        }

        img {
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        margin: 15px 0 0 0;
        font-weight: $bold;
        font-size: 22px;
        color: $black;
        @include respond(small) {
          margin: 20px 0 0;
          font-size: 16px;
        }
      }

      .dates {
        margin: 5px 0 0 0;
        font-weight: $bold;
        font-size: 15px;
        color: #000000;
        @include respond(small) {
          margin: 10px 0 0;
          font-size: 11px;
        }
      }

      .description {
        margin: 10px 0 0 0;
        font-weight: $semibold;
        font-size: 16px;
        color: #DD2020;
        line-height: 20px;
        border-bottom: 1px dashed #DD2020;
        @include respond(small) {
          font-size: 13px;
          white-space: nowrap;
        }
      }
    }

    .owl-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;

      .owl-dot {
        width: 10px;
        height: 10px;
        box-shadow: none;
        background: $red;
        border: 2px solid $t;
        transition: all ease .2s;
        transform: scale(.5);

        &:not(:last-child) {
          margin: 0 10px 0 0;
        }

        &.active {
          background: $t;
          border-color: $red;
          transform: scale(1);
        }
      }
    }
  }

  .products {
    margin: 50px 0 0 0;
    text-align: center;
    @include respond(small) {
      width: 100vw;
      margin-left: -38px;
    }

    .show-more {
      @include borderedButton();
      display: none;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      &.active.show {
        display: flex;
      }
    }

    .wrapper {
      display: flex;
      width: 100%;
      min-height: 750px;
      flex-wrap: wrap;

    }

    .view-all {
      border-bottom: 1px dashed $red;

      &.active {
        border-bottom-color: $t;
      }

      &, .text {
        font-weight: $bold;
        font-size: 22px;
        color: $red;
        text-align: left;

        @include respond-down(medium) {
          font-size: 18px;
        }
        @include respond(small) {
          font-size: 16px;
        }
      }

      .text {
        margin-left: 10px;
        border-bottom: 1px dashed currentColor;
      }
    }

    .items-wrapper {
      @include respond-down(medium) {
        position: relative;
        background: $white;
        &.active {
          padding: 0 0 70px 0;
        }
      }
      @include respond(small) {
        &.active {
          padding: 0 0 55px 0;
        }
      }
    }

    .items {
      display: none;
      margin: 40px 0 0 0;
      overflow: hidden;
      transition: all ease-out .3s;


      &.active {
        display: block;
        overflow: initial;
      }

      .owl-dots {
        display: flex;
        position: absolute;
        bottom: 50px;
        left: 50%;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);

        .owl-dot {
          opacity: .3;
          width: 50px;
          height: 50px;
          box-shadow: none;
          background: $t;
          border: 2px solid $red;
          counter-increment: sliderDot;

          &:not(:last-child) {
            margin: 0 20px 0 0;
          }

          &::before {
            content: counter(sliderDot);
            font-weight: $bold;
            font-size: 22px;
            color: $black;
            text-align: center;
          }

          &.active {
            opacity: 1;
            border: 3px solid $red;
          }
        }
      }

      .item {

      }

      .owl-stage-outer {
        background: #fff;
        border-radius: 7px;
      }

      .wrapper {
        display: flex;
        width: 100%;
        padding: 40px 70px 130px;
        flex-wrap: wrap;
        justify-content: space-between;
        @include respond-down(medium) {
          display: none;
          background: #fff;
          padding: 90px 65px;
          &:first-child {
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
          }
          &:last-child {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
          }
          &.active {
            display: flex;
          }
        }
        @include respond(small) {
          padding: 60px 20px;
        }

        .product {
          display: flex !important;
          width: 150px;
          flex: 0 0 33%;
          align-items: center;
          flex-direction: column;

          @include respond(medium) {
            flex: 0 0 50%;
            &:nth-child(-n+3) {
              margin: 0 0 73px 0;
            }
          }
          @include respond(small) {
            flex: 0 0 50%;
            &:nth-child(-n+3) {
              margin: 0 0 64px 0;
            }
          }

          .image-wrapper {
            display: flex;
            margin: 0 0 10px 0;
            height: 220px;
            width: 120px;
            @include respond(small) {
              height: 140px;
            }

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }

          .name {
            display: block;
            max-width: 150px;
            font-weight: $bold;
            font-size: 18px;
            color: #000;
            line-height: calc(20 / 18);
            text-align: center;
            @include respond(small) {
              font-size: 13px;
              color: #000000;
              text-align: center;
              line-height: calc(18 / 13);
            }
          }
        }
      }
    }
  }

  .map-wrapper {
    position: relative;

    @include respond(small) {
      width: 100vw;
      margin-left: -38px;
    }

    .column {
      position: relative;
    }

    .search-bar-wrapper {
      position: absolute;
      width: 100%;
      height: 50px;
      top: 50px;
      right: 0;
      left: 0;
      z-index: 10000;

      @include respond(medium) {
        width: calc(100% - 80px);
        margin: 0 auto;
      }
      @include respond(small) {
        width: calc(100% - 40px);
        margin: 0 auto;
      }

      .search-bar {
        padding: 14px 55px 14px 23px;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 4px;
        border: none;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.21);

        &, &::placeholder {
          opacity: 1;
          font-weight: 100;
          font-size: 18px;
          color: #000000;
          text-align: left;

          @include respond-down(medium) {
            font-size: 16px;
          }
        }
      }

      .svg-icon {
        position: absolute;
        z-index: 3;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @include respond-down(medium) {
          zoom: calc(17 / 20);
        }
      }

    }

    #map {
      display: block;
      margin: 20px 0 0 0;
      height: 673px;
      width: 100%;
      @include respond(medium) {
        height: 560px;
      }
      @include respond(small) {
        height: 420px;
      }
    }
  }

  .close-icon {
    display: inline-block;
    width: 18px;
    height: 17px;
    background-size: contain;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23DD2020' fill-rule='nonzero'%3E%3Cpath d='M1.86464943.16211125l15.42778431 15.42778431-1.15708382 1.15708382L.70756561 1.31919508z'/%3E%3Cpath d='M.70756625 15.58989557L16.13535056.16211126l1.15708382 1.15708382-15.4277843 15.42778431z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(3px);

    @include respond(small) {
      transform: translateY(4px);
    }
  }
}