&.partner-block {
  display: block;
  position: relative;
  width: 100%;
  background: $white;
  z-index: 2;
  @include respond(large) {
    padding: 53px 0 73px 0;
  }
  @include respond(middle) {
    margin: 50px 0 0 0;
    padding: 0 0 55px 0;
  }
  @include respond(medium) {
    padding: 80px 0 68px 0;
  }
  @include respond-down(small){
    padding: 50px 0 68px 0;
  }

  .food {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
    overflow-x: hidden;

    .image {
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      @include respond(middle) {
        transform-origin: top left;
        transform: scale(.7);
      }

      &.left {
        width: 471px;
        height: 521px;
        top: -132px;
        left: 0;
        background-image: url('../images/pages/index/blocks/partner-block/left.png');
        @include respond(middle) {
          top: -145px;
          left: -91px;
        }
      }

      &.right {
        width: 393px;
        height: 652px;
        top: 0;
        right: 0;
        background-image: url('../images/pages/index/blocks/partner-block/right.png');
        @include respond(middle) {
          right: -51px;
          transform-origin: top right;
        }
      }

      &.currant {
        width: 211px;
        height: 141px;
        bottom: 82px;
        left: 156px;
        background-image: url('../images/pages/index/blocks/partner-block/currant.png');
        @include respond(middle) {
          bottom: 71px;
          left: 43px;
        }
      }
    }
  }

  .block-title {
    .title {
      margin: 0;
      @include respond-up(medium) {
        white-space: nowrap;
      }
    }

    .text {
      margin: 44px 0 0 0;
      text-align: center;
      font-size: 21px;
      color: $black;
      line-height: 30px;
      @include respond(middle) {
        margin: 28px 0 0 0;
      }
      @include respond(medium) {
        margin: 35px 0 0 0;
      }
      @include respond(small) {
        margin: 31px 0 0 0;
      }
    }
  }

  .block-content {
    @include respond(large) {
      margin: 61px -6px 0;
    }
    @include respond(middle) {
      margin: 39px -30px 0;
    }
    @include respond(medium) {
      margin: 36px 0 0 0;
    }

    .form {
      display: block;
      position: relative;

      &:before {
        opacity: 0;
        content: 'Спасибо! Мы ответим Вам в ближайшее время.';
        display: flex;
        position: absolute;
        padding: 20px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 1.5rem;
        text-align: center;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        transition: opacity ease-out .15s;
      }

      &.success {
        &:before {
          opacity: 1;
          pointer-events: all;
        }

        * {
          opacity: 0;
          pointer-events: none;
        }
      }

      @include respond-up(medium) {
        border: 4px solid $red;
        border-radius: 25px;
        box-shadow: 0 2px 25px 0 rgba(0, 0, 0, .14);
      }
      @include respond(large) {
        padding: 52px 43px 36px;
      }
      @include respond(middle) {
        padding: 39px 32px 25px;
      }
      @include respond(medium) {
        padding: 39px 40px 25px;
      }
      @include respond(small) {
        margin: 61px 0 0 0;
        padding: 0 0 35px 0;
      }

      .inputs {
        display: flex;
        margin: 0 0 32px 0;
        @include respond-up(medium) {
          justify-content: space-between;
        }
        @include respond(middle) {
          margin: 0 0 24px 0;
        }
        @include respond(medium) {
          margin: 0 0 23px 0;
        }
        @include respond(small) {
          flex-wrap: wrap;
          flex-direction: column;
        }

        .form-field {
          width: calc(50% - 34px);
          @include respond(middle) {
            width: calc(50% - 25px);
          }
          @include respond(small) {
            width: 100%;
            &:not(:last-child) {
              margin: 0 0 23px 0;
            }
          }

          input {
            @include respond-down(middle) {
              height: 38px;
            }
          }
        }
      }

      .form-field {
        @include respond(small) {
          &.text {
            margin: -7px 0 0 0;
          }
        }

        textarea {
          height: 136px;
          @include respond(middle) {
            height: 102px;
          }
          @include respond(medium) {
            height: 102px;
          }
          @include respond(small) {
            height: 248px;
          }
        }
      }

      .button {
        width: 234px;
        @include respond(large) {
          margin: 30px auto 0 auto;
        }
        @include respond(middle) {
          margin: 23px auto 0 auto;
          width: 176px;
          height: 40px;
        }
        @include respond(medium) {
          margin: 25px auto 0;
          width: 176px;
          height: 40px;
        }
        @include respond(small) {
          margin: 25px auto 0 auto;
          width: 70.4%;
          height: 40px;
        }
      }
    }
  }
}