.video-block {
  margin: 117px 0 0 0;
  padding: 0 0 82px 0;
  @include respond(middle) {
    margin: 105px 0 0 0;
  }
  @include respond(medium) {
    margin: 113px 0 0 0;
    padding: 0 0 113px 0;
  }
  @include respond(small) {
    margin: 6px 0 41px 0;
    padding: 0 0 41px 0;
  }

  .block-content.video {
    position: relative;
    @include respond(medium) {
      max-width: 458px;
      margin: 0 auto;
    }

    .notebook {
      display: block;
      position: absolute;
      width: 47.110057803%;
      height: 124.25%;
      top: -37px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../images/pages/index/blocks/why-block/macbook.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      pointer-events: none;
      z-index: 11;

      @include respond-down(middle) {
        width: 616px;
        height: 366px;
        top: -25px;
        background-image: url("../images/pages/index/blocks/why-block/macbook-middle.png");
      }
      @include respond(small) {
        display: none;
        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 22px 33px 80px #d4e3ec;
      }

    }

    .owl-carousel {
      position: relative;
      z-index: 8;

      .owl-stage-outer {
        .owl-stage {
          .owl-item {
            position: relative;
            filter: saturate(.75);

            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba($white, .75);
              z-index: 10;
              pointer-events: none;
            }

            .video-wrapper {
              height: 400px;

              @include respond(middle) {
                height: 300px;
              }
              @include respond(medium) {
                height: 300px;
              }
              @include respond(small) {
                height: 166px;
              }
            }

            @include respond-up(middle) {
              pointer-events: none;
            }

            &.no-mask {
              filter: none;

              @include respond-up(middle) {
                pointer-events: all;
              }

              &:before {
                pointer-events: none;
                opacity: 0;
              }

              .video-wrapper {
                cursor: pointer;
              }
            }
          }
        }
      }

      .owl-nav {
        @include respond(medium) {
          top: 38.8%;
        }

        .owl-prev {
          @include respond(medium) {
            left: -15.4%;
            .carousel-prev {

            }
          }
          @include respond(small) {
            left: 10px;
            bottom: -148px;
          }
        }

        .owl-next {
          @include respond(medium) {
            right: -16.4%;
            .carousel-next {

            }
          }
          @include respond(small) {
            right: 10px;
            bottom: -148px;
          }
        }
      }
    }
  }

  .button {
    width: 240px;
    height: 50px;
    margin: 100px auto 0;
    @include respond-down(medium) {
      margin: 70px auto 0;
      height: 45px;
    }
    @include respond(small) {
      width: 210px;
      margin: 30px auto 0;
    }
  }
}