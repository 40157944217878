.cart-widget {
  display: flex;
  position: fixed;
  height: 65px;
  padding: 0 24px 0 13px;
  top: 45vh;
  right: 0;
  flex-direction: row;
  align-items: center;
  background-color: #E3000F;
  user-select: none;
  color: #ffffff;
  overflow: hidden;
  transform: translateX(calc(100% - 66px));
  transition: transform ease-out .2s;
  pointer-events: all;
  z-index: 8000;

  &:hover{
    transform: translateX(0);
    .cart-widget-text{
      opacity: 1;

    }
  }

  &-total{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-content: center;
    justify-content: space-around;
    padding-top: 2px;
    font-size: 10px;
    background-color: #fff;
    color: #e3000f;
    border-radius: 10px;
    text-align: center;
    width: 15px;
    height: 15px;
    left: 44px;
    top: 7px;
  }

  &-cart{
    display: inline-block;
    width: 43px;
    height: 43px;
    color: #ffffff;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    zoom: .9;
  }

  &-text {
    opacity: 0;
    padding: 4px 0 0 8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    white-space: nowrap;
    transition: all ease-out .2s;
    color: #ffffff;
  }
}