body.catalog-view-page {
  position: static;
}

.catalog-view-page {
  position: relative;
  z-index: 100;

  .recipes-block {
    display: block;
    padding: 100px 0 80px 0;
  }

  .why-block {
    background-image: none;
  }

  .partner-block {
    padding: 130px 0 0 0;
    height: 850px;
    background-image: url('../images/pages/catalog/partner-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $t;

    @include respond(middle) {
      padding: 110px 0 0 0;
      height: 700px;
    }
    @include respond-down(medium) {
      height: auto;
      margin: 50px 0 0;
      padding: 50px 0;
      background-image: none;
      background: $white;
    }
    @include respond(small) {
      padding: 33px 0;
    }

    .food {
      top: 100px;
    }
  }

  .breadcrumbs-block {
    @include respond-down(medium) {
      opacity: 0;
    }
    @include respond-down(small) {
      display: none;
    }
  }

  .product-block {
    display: block;
    margin: 55px 0 0 0;
    @include respond-down(small) {
      margin: 100px 0 0;
    }

    .benefits {
      @include respond-down(small) {
        display: flex;
        justify-content: center;
      }

      .benefit {
        @include respond-down(medium) {
          transform: scale(.8);
          transform-origin: center center;
        }

        &:not(:last-child) {
          margin-bottom: 65px;
          @include respond-down(medium) {
            margin-bottom: 20px;
          }
          @include respond-down(small) {
            &:not(:last-child) {
              margin-right: 13px;
            }
          }
        }
      }
    }

    .image-wrapper {
      display: flex;
      height: 448px;
      justify-content: center;
      align-items: center;
      @include respond-down(medium) {
        height: 300px;
      }

      @include respond-down(small) {
        padding-top: 20px;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .text-block {
      .characteristics {
        @include respond-down(small) {
          margin: 20px 0 0 0;
        }

        .title {
          display: block;
          margin: 0 0 30px 0;
          font-weight: $bold;
          color: $darkorange2;
          @include respond-property(font-size, 24px, 26px, 28px, 30px);
        }

        .characteristics-list {
          .list-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 10px;
              @include respond-down(middle) {
                margin-bottom: 7px;
              }
            }

            &.specification, &.temperature {
              @include respond-down(small) {
                .item-title {
                  width: 140px;

                  .title {
                    white-space: normal;
                    //line-height: 1.1;
                  }

                }
              }
            }

            &.temperature {
              @include respond-down(small) {
                .description {
                  white-space: nowrap;
                }
              }
            }

            .item-title {
              display: flex;
              width: 263px;
              flex-direction: row;
              align-items: center;
              @include respond-down(middle) {
                flex: 0 0 230px;
              }
              @include respond(small) {
                flex: initial;
                width: initial;
              }

              .svg-icon {
                flex: 0 0 28px;
              }

              .title {
                margin: 0 0 0 16px;
                color: $darkorange;
                line-height: 30px;
                white-space: nowrap;
                @include respond-property(font-size, 16px, 17px, 18px, 20px);
              }
            }

            .description {
              margin: 0 0 0 24px;
              color: $choco;
              line-height: 30px;
              text-align: left;
              @include respond-property(font-size, 16px, 17px, 18px, 20px);
            }

          }
        }

        .ask-question {
          margin: 31px 50px 0 0;
          width: 234px;
          display: inline-flex;
          line-height: 53px;
          @include respond-down(middle) {
            width: 176px;
            height: 40px;
          }
          @include respond(small) {
            width: 70.4%;
          }
        }
      }
    }
  }

  .description-block {
    margin: 31px 0 0 0;
  }

  .other-products-block {
    margin: 48px 0 33px 0;

    .owl-prev {
      left: 21px;
      transform: translateY(-50%);
    }

    .owl-next {
      right: 21px;
      transform: translateY(-50%);
    }
  }

  .block-title {
    display: block;
    margin: 0 0 24px 0;
    font-weight: $bold;
    font-size: 24px;
    color: #b80202;
    line-height: 24px;
    @include respond-property(font-size, 18px, 20px, 22px, 24px);

    &.large {
      margin: 0 0 39px 0;
      font-size: 30px;
      @include respond-property(font-size, 24px, 26px, 28px, 30px);
    }
  }

  .block-description {
    color: $darkorange2;
    line-height: 24px;
    @include respond-property(font-size, 16px, 17px, 18px, 20px);
  }


  .product-slider {
    .owl-nav {
      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }
  }

  .other-packages-wrapper {
    margin: 23px 0 0;
  }

  .other-packages {
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: flex;
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
      border-radius: 15px;
      border: 1px solid #000000;
      position: relative;
      align-items: center;
      justify-content: center;
      color: $black;
      transition: all ease-out .1s;

      &:hover {
        color: $red;
        border-width: 2px;
        border-color: $red;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.doypack {
        .text {
          padding-left: 3px;
        }
      }

      @include respond-down(medium) {
        flex: 0 0 65px;
        width: 65px;
        height: 65px;
        margin-top: 10px;
      }

      .svg-icon {
        position: absolute;
        @include respond-down(medium) {
          zoom: .8;
        }
      }

      .text {
        margin-top: 8px;
        font-weight: $bold;
        font-size: 14px;
        @include respond-down(medium) {
          font-size: 11px;
        }
      }
    }
  }

  .ask-block {
    background-image: url('../images/parts/texture-900.jpg');
    background-size: 912px 937px;
    background-repeat: repeat;
    padding-bottom: 35px;
    margin-top: 25px;
  }

  .ask-technologist {
    margin: 75px 0 75px 0;
    padding: 54px 90px 63px;
    border-radius: 25px;
    background: $white;

    @include respond(middle) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include respond-down(medium) {
      margin: 35px 0 0 0;
    }
    @include respond-down(small) {
      margin: 55px -20px 0;
      padding: 35px 20px 0;
    }

    .title {
      margin: 0 0 50px;
      font-weight: $bold;
      font-size: 30px;
      color: $black;
      text-align: center;
      @include respond-down(small) {
        margin-bottom: 30px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      &.success {
        .form-fields {
          &:before {
            opacity: 1 !important;
          }
        }
        .button {
          pointer-events: none;
        }
      }

      .wrapper {
        display: flex;

        .image {
          display: block;
          width: 494px;
          height: 452px;
          flex: 0 0 50%;
          border-radius: 25px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('../images/pages/catalog/man-w-girl.jpg');

          @include respond-down(medium) {
            display: none;
          }
        }

        .form-fields {
          display: flex;
          position: relative;
          padding-left: 20px;
          flex-wrap: wrap;

          &:before {
            opacity: 0;
            content: 'Спасибо! Мы ответим Вам в ближайшее время';
            display: flex;
            position: absolute;
            padding: 20px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 1.5rem;
            text-align: center;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            background: $white;
            transition: opacity ease-out .15s;
          }

          @include respond(small) {
            padding-left: 0;
          }

          p {
            display: block;
            width: 100%;
            margin-bottom: 25px;
            font-size: 17px;
            color: $black;
            line-height: calc(25 / 17);
          }

          .form-field {
            margin: 0 0 20px;
            @include respond-up(medium) {
              width: calc(50% - 9px);
              &:first-of-type {
                margin-right: 9px;
              }

              &:nth-of-type(2) {
                margin-left: 9px;
              }
            }
            @include respond-down(small) {
              width: 100%;
            }

            &.text {
              @include respond-up(medium) {
                width: 100%;
              }

              textarea {
                min-height: 200px;
              }
            }
          }
        }
      }

      .button {
        margin: 44px auto 0;
        width: 234px;
        height: 53px;
        @include respond-down(medium) {
          margin-top: 10px;
        }
      }
    }
  }
}