.footer {
  display: block;
  position: relative;
  height: inherit;
  background: url('../images/parts/texture.png') no-repeat 0 0;
  background-size: cover;

  &.no-bg {
    background-color: initial !important;
    background-image: none !important;
  }

  @include respond(large) {
    padding-bottom: 40px;
  }
  @include respond(middle) {
    padding-bottom: 60px;
  }
  @include respond-down(medium) {
    background-size: 912px 937px;
    background-repeat: repeat;
    background-image: url("../images/parts/texture-900.jpg");
  }
  @include respond(small) {
    height: inherit;
    padding-bottom: 90px;
  }

  .wrapper {
    margin: 169px 0 0;
    @include respond(middle) {
      margin: 158px 0 0;
    }
    @include respond(medium) {
      margin: 56px 0 0;
    }
    @include respond(small) {
      margin: 42px 0 0;
    }

    .left-block {

    }

    .center-block {
      @include respond-down(medium) {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: $white;
      }
      @include respond(medium) {
        margin: 0 0 80px 0;
        padding: 0 0 27px 0;
      }
      @include respond(small) {
        margin: 0 0 53px 0;
      }

      .vk-group {
        .vk-group-frame {

        }

        .vk-group-frame-medium-down {

        }
      }

      .copy {
        margin: 57px 0 0 0;
        text-align: center;
        font-size: 14px;
        color: $black;
        line-height: 20px;
        @include respond(middle) {
          margin: 65px 0 0 0;
        }
      }
    }

    .right-block {
      @include respond-up(middle) {
        margin-left: -20px;
      }
      @include respond(medium) {
        margin-left: 28px;
      }
    }

    .footer-text-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .footer-text {
      margin: 0 0 28px 0;

      &.catalog {
        .text {
          .item {
            &:not(:last-child) {
              margin: 0 0 20px 0;
            }
          }
        }
      }

      &.address {

      }

      &.time {

      }

      &.phone {

      }

      .title {
        margin: 0 0 20px 0;
        font-weight: $bold;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 20px;
      }

      .text {
        display: flex;
        flex-direction: column;

        &, .item, a {
          color: $black;
          font-size: 18px;
          line-height: 20px;

          a:hover {
            text-decoration: underline
          }
        }

        .item {

        }
      }
    }

  }
}