@font-face {
    font-family: 'Forte_VR';
    src: url('ForteVR.eot');
    src: url('ForteVR.eot?#iefix') format('embedded-opentype'),
        url('ForteVR.woff2') format('woff2'),
        url('ForteVR.woff') format('woff'),
        url('ForteVR.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

