.modal-form {
  position: relative;
  padding: 0 20px 20px;
  @include respond-down(small) {
    padding: 0 10px 10px;
  }

  &.success {
    .wrapper * {
      pointer-events: none;
    }

    .modal-form-content {
      opacity: 0;
    }


    &:before {
      content: 'Спасибо! Мы свяжемся с Вами в ближайшее время.';
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      line-height: 1.2;
      text-align: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background: $white;
    }

    .wrapper .modal-success-arrow {
      display: block;
    }
  }

  .wrapper {
    .modal-form-title {
      font-size: 1.5rem;
      font-family: $font;
      font-weight: $bold;
      text-align: center;
    }

    .modal-form-content {

      .button {
        width: 100%;
        margin: 15px 0 0 0;
        height: 45px;
      }

      .form-field {
        &:not(:last-child) {
          margin: 0 0 12px;
        }
      }

    }

    .modal-success-arrow {
      display: none;
      position: absolute;
      top: calc(50% - 60px);
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);

      .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #7ac142;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }

      .checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      }

      .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      }

      @keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes scale {
        0%, 100% {
          transform: none;
        }
        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
      @keyframes fill {
        100% {
          box-shadow: inset 0px 0px 0px 30px #7ac142;
        }
      }
    }
  }

}