&.wholesaler-block {
  display: block;
  position: relative;
  width: 100%;
  background: $white;
  overflow-x: hidden;

  @include respond(large) {
    padding: 62px 0 0 0;
  }
  @include respond(middle) {
    padding: 140px 0 0 0;
  }
  @include respond(medium) {
    padding: 63px 0 0 0;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 37px;
    top: -37px;
    right: 0;
    left: 0;
    z-index: 11;
    background-image: url("../images/pages/index/blocks/line.png");
    background-size: 100% 100%;
  }

  .food {
    .image {
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      &.tomato {
        width: 184px;
        height: 169px;
        top: 41px;
        left: 43px;
        background-image: url('../images/pages/index/blocks/wholesaler-block/tomato.png');
        @include respond(middle) {
          top: 130px;
          left: 85px;
          width: 130px;
          height: 119.4px;
        }
      }

      &.cutted-pepper {
        width: 313px;
        height: 357px;
        top: 40px;
        right: 0;
        background-image: url('../images/pages/index/blocks/wholesaler-block/cutted-pepper.png');
        background-position: center right;
        @include respond(middle) {
          width: 259px;
          height: 295px;
          top: 106px;
          right: -82px;
        }
      }
    }
  }

  .sauce-bottle {
    display: block;
    width: 322px;
    height: 660px;
    background-image: url("../images/pages/index/blocks/wholesaler-block/sauce.png");
    background-repeat: no-repeat;
    background-size: contain;
    @include respond-up(large) {
      margin-left: -80px;
    }

    @include respond(middle) {
      width: 242px;
      height: 495px;
    }

    @include respond(medium) {
      width: 218px;
      height: 490px;
    }
  }

  .block-title {
    @include respond(large) {
      padding: 110px 0 0 0;
    }
    @include respond(middle) {
      padding: 90px 0 0 0;
    }
    @include respond(medium) {
      padding: 62px 0 0 0;
    }
    @include respond(small) {
      padding: 60px 0 0 0;
    }

    .title {
      margin: 0;
      @include respond-up(medium) {
        white-space: nowrap;
      }
    }

    .text {
      margin: 50px 0 0 0;
      font-size: 21px;
      color: $black;
      line-height: 30px;

      @include respond(large) {
        // wtf
        width: 107%;
      }
      @include respond(middle) {
        margin: 24px 0 0 0;
      }
      @include respond-down(medium) {
        margin: 29px 0 0 20px;
        font-size: 16px;
      }
      @include respond(small) {
        margin: 0;
      }
    }

    .button {
      width: 238px;
      margin: 40px 0 0 0;
      @include respond-down(middle) {
        width: 203px;
        height: 40px;
        font-size: 20px;
      }
      @include respond(medium) {
        margin: 42px 0 0 20px;
        font-size: 16px;
      }
      @include respond(small) {
        width: 100%;
      }
    }
  }
}