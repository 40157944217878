.filter__items {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 10;

  @include respond(small) {
    position: absolute;
    width: calc(100% - 10px);
    background: $red;
    margin: 0;
    padding: 0 0 40px 25px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom-left-radius: 28.5px;
    border-bottom-right-radius: 28.5px;

    transition: all .2s ease-out;
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    z-index: 11;

    &.active {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }
  }
}
.filter__item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include respond-up(medium) {
    min-width: 150px;
    width: calc(100% / 6 - 19px);
    height: 57px;
    border-style: solid;
    border-width: 2px;
    border-radius: 28.5px;
    border-color: $black45;
    color: $black000;
    transition: all ease-out .2s;
    margin-bottom: 20px;
    &:not(:first-child) {
      margin-right: 10px;
      margin-left: 10px;
    }
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active, &:hover {
      background: $red;
      border-color: $t;
      color: $white;
    }
    &.number-6 {
      margin-left: 0;
    }
  }
  @include respond(medium) {
    width: calc(100% / 3 - 17px);
    margin: 24px 10px 0 0;
    &.number-0, &.number-1, &.number-2 {
      margin-top: 0;
    }
    &.number-3 {
      margin-left: 0;
    }
  }
  @include respond(small) {
    font-size: 16px;
    text-decoration: underline;
    text-align: left;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}