.promo-page {
  .modal__container {
    padding: 0;
    max-width: 1240px;
    width: 100%;
  }
}

.promo__goods {
  width: 100%;
  padding: 90px 110px;
  @include respond(middle) {
    padding: 45px 55px;
  }
  @include respond-down(medium) {
    padding: 40px 0;
  }
  @at-root &_title {
    min-height: 75px;
    margin: 0 0 95px;
    font-weight: $bold;
    font-size: 35px;
    color: $black;
    text-align: center;
    line-height: calc(45 / 35);
    @include respond(middle) {
      margin: 0 0 47px;
      font-size: 30px;
    }

    @include respond-down(medium) {
      font-size: 25px;
    }

    @include respond-down(medium) {
      margin: 0 0 30px;
    }
  }
  @at-root &_grid {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @at-root &_row {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    @include respond-down(small) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &:not(:last-child) {
      margin-bottom: 100px;
      @include respond(middle) {
        margin-bottom: 60px;
      }
      @include respond(small) {
        margin-bottom: 30px;
      }
    }

    &.hidden {
      display: none;
    }
  }
  @at-root &_item {
    height: 100%;
    flex: 0 0 33%;
    align-items: center;
    @include respond(small) {
      flex: initial;
      height: initial;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &:nth-child(2) {
      .promo__goods_item-wrapper {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:nth-child(3) {
      .promo__goods_item-wrapper {
        margin-left: auto;
      }
    }
  }
  @at-root &_item-wrapper {
    display: flex;
    max-width: 162px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @at-root &_image {
    height: 160px !important;
    object-fit: contain;
  }
  @at-root &_name {
    min-height: 50px;
    width: 100%;
    margin: 20px 0 0;
    font-weight: 200;
    font-size: 16px;
    color: #000000;
    text-align: left;
    line-height: 25px;
  }
  @at-root &_grammage {
    white-space: nowrap;
  }
  @at-root &_price {
    margin: auto 0 0;
    padding: 8px 0 0;
    width: 100%;
    font-weight: $bold;
    font-size: 17px;
    color: $red;
    text-align: left;
  }
  @at-root &_button {
    @include borderedButton;
    margin: 0 auto 0;
  }
}