body.news-view-page {
  position: relative;
  background-image: url('../images/pages/index/blocks/texture-1.jpg');
  background-repeat: repeat;
  z-index: 1;
  overflow-x: hidden;

  .bg-images {
    display: block;
    pointer-events: none;

    & > * {
      display: block;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      top: 300px;
    }

    .news-view__left {
      left: 0;
      width: 263px;
      height: 1123px;
      background-image: url('../images/pages/news/news__view-left.png');
    }

    .news-view__right {
      right: 0;
      width: 267px;
      height: 1019px;
      background-image: url('../images/pages/news/news__view-right.png');
    }
  }
}

.news-view-page {
  display: block;

  .wrapper {
    padding: 0 0 120px 0;

    .breadcrumbs-block {
      @include respond-down(medium) {
        opacity: 0;
        visibility: hidden;
      }
    }

    @include respond(medium) {
      padding: 0 0 100px 0;
    }
    @include respond(small) {
      padding: 0 0 60px 0;
    }
  }

  .page-title {
    font-family: $font;
    font-size: 40px;
    color: $red;
    text-align: center;
    line-height: calc(49 / 40);
    font-weight: 500;

    @include respond(medium) {
      font-size: 35px;
      line-height: calc(42 / 35);
    }
    @include respond(small) {
      font-size: 28px;
      line-height: calc(34 / 28);
    }
  }

  .content {
    display: flex;
    margin: 80px 0 0 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include respond(small) {
      margin: 40px 0 0 0;
    }

    .item {
      position: relative;

      @include respond-down(medium) {
        margin: 0 auto;
      }
      @include respond(medium) {
        max-width: 490px;
      }
      @include respond(small) {

      }

      .date {
        display: flex;
        position: absolute;
        height: 83px;
        width: 83px;
        top: -40px;
        left: -40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $red;
        border-radius: 10px;
        text-align: center;
        color: $white;
        font-weight: $bold;

        @include respond(small) {
          width: 54px;
          height: 57px;
          left: -20px;
          top: -12px;
        }

        .day {
          font-size: 35px;

          @include respond(small) {
            font-size: 20px;
          }
        }

        .month {
          font-size: 18px;

          @include respond(small) {
            font-size: 13px;
          }
        }
      }

      .image {
        display: flex;
        height: 310px;
        @include respond(small) {
          height: 160px;
          margin: 0 0 40px 0;

        }

        &, & > img {
          width: 100%;
        }

        img {
          height: auto;
          object-fit: cover;
        }
      }

      .title {
        font-weight: $bold;
        font-size: 25px;
        color: $red;
        line-height: calc(32 / 25);
        @include respond(small) {
          font-size: 16px;
          line-height: 2;
        }
      }

      .description {
        margin: 80px 0 0;
        font-size: 18px;
        color: $black;
        line-height: calc(25 / 18);
        @include respond(small) {
          font-size: 14px;
          line-height: calc(20 / 14);
        }
      }
    }


    .filter-items {
      display: flex;
      margin: 55px auto 0;
      justify-content: center;
      flex-wrap: wrap;
      @include respond(small) {
        max-width: 400px;
      }

      .item {
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: rgba($red, .11);
        border: 2px solid #B80202;
        border-radius: 25px;
        padding: 14px 26px;

        @include respond(small) {
          &:nth-child(2n) {
            margin-right: 0 !important;
          }
          &:nth-child(n+3) {
            margin-top: 16px !important;
          }
        }

        &, & > .link {
          transition: all ease-out .2s;
          cursor: pointer;
        }

        &.active, &:hover {
          background: #E3000F;
          border-color: $t;

          .link {
            color: $white;
          }
        }

        &:not(:last-child) {
          margin: 0 20px 0 0;
        }

        .link {
          font-weight: $bold;
          font-size: 18px;
          color: #B4110B;
          text-align: left;
          @include respond(small) {
            font-size: 15px;
          }
        }
      }
    }

    .back-button {
      @include borderedButton();

    }
  }
}