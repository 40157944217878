.block-title {
  display: flex;
  flex-direction: column;

  .title {
    margin: 0 0 17px 0;
    font-family: $h-font;
    @include respond-property(font-size, 30px, 40px, 50px, 60px);
    color: $black;
    text-align: center;
    @include respond(middle) {
      margin: 0 0 7px 0;
    }
    @include respond-down(medium) {
      margin: 0 0 9px 0;
      &.small {
        font-size: 33px;
      }
    }
    @include respond(small) {
      &.small {
        line-height: 36px;
        font-size: 30px;
      }
    }

    .red, &.red {
      color: $red;
    }

    &.left {
      text-align: left;
    }
  }

  .description {
    font-weight: $semibold;
    @include respond-property(font-size, 14px, 20px, 20px, 24px);
    color: $black;
    text-align: center;
    line-height: 22px;
  }
}