.header {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &.red {
    @include respond-property(height, 104px, 113px, 160px, 160px);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url('../images/parts/header/header-red.png');
    @include respond(medium) {
      background-size: 200% 100%;
    }
    @include respond(small) {
      background-size: 250% 100%;
    }
  }

  .menu-items {
    display: flex;
    position: relative;
    width: 100%;
    margin: 55px 0 0 0;
    justify-content: space-between;

    @include respond(middle) {
      margin: 35px 0 0 0;
    }

    &.left {
      @include respond(middle) {
        padding: 0 50px 0 0;
      }
    }

    &.right {
      @include respond(middle) {
        padding: 0 0 0 33px;
      }
    }

    .menu-item {
      position: relative;
      white-space: nowrap;

      &:hover {
        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 39px;
          top: 0;
          right: -27px;
          bottom: 0;
          left: -27px;
          border: 2px solid rgba($white, .5);
          background: $t;
          box-shadow: none;
          line-height: 1;
          border-radius: 26.5px;

          @include respond(middle) {
            height: 30px;
            left: -10px;
            right: -10px;
            top: 5px;

          }
          @include respond-down(medium) {
            content: none;
          }
        }
      }
    }

    .menu-item {
      display: block;
      position: relative;
      z-index: 10;
      line-height: 42px;

      &.has-children {
        &:before {
          opacity: 0;
          content: "";
          display: block;
          position: absolute;
          left: -21px;
          width: 237px;
          height: 42px;
          background: $black;
          border-radius: 0;
          border: 0;
          border-top-left-radius: 21px;
          border-top-right-radius: 21px;

          pointer-events: none;
          transition: opacity .2s ease-out;
          top: 0;
          @include respond(middle) {
            top: 0;
          }
        }

        &.active {
          z-index: 11;

          &:before,
          .children-container {
            pointer-events: all;
            opacity: 1;
          }
        }
      }

      .menu-item-link {
        position: relative;
        @include respond-property(font-size, 15px, 15px, 14px, 16px);
        font-weight: $bold;
        text-transform: uppercase;
        color: $white;
        z-index: 10;
      }

      .children-container {
        opacity: 0;
        width: 236px;
        min-height: 100%;
        background: url('../images/parts/header/texture_menu.jpg') repeat 50% 50%;
        position: absolute;
        top: 42px;
        left: -21px;
        z-index: 9;
        border-bottom-left-radius: 21px;
        border-bottom-right-radius: 21px;
        pointer-events: none;
        transition: opacity .2s ease-out;

        .children-items, .subchildren-items {
          display: block;
          width: 100%;
          height: 100%;
          padding: 18px 26px 17px 21px;

          .children-item, .subchildren-item {
            display: flex;
            width: 100%;
            white-space: initial;

            &:not(:last-child) {
              margin: 0 0 24px 0;
            }

            justify-content: space-between;
            align-items: center;
            font-weight: $bold;
            font-size: 16px;
            line-height: 1.2;
            color: $black;

            .svg-icon {
              &.icon-menu-arrow {
                color: $black;
              }
            }

            &:hover, &.active {
              color: $red;

              .svg-icon {
                &.icon-menu-arrow {
                  color: $red;
                }
              }
            }
          }
        }

        .subchildren-items {
          opacity: 0;
          pointer-events: none;
          width: 235px;
          height: max-content;
          position: absolute;
          top: 0;
          left: 235px;
          border-bottom-right-radius: 21px;
          border-top-right-radius: 21px;
          background: url('../images/parts/header/texture_menu.jpg') repeat 50% 50%;
          transition: opacity .2s ease-out;

          &.active {
            opacity: 1;
            pointer-events: all;
          }

          .subchildren-item {

          }
        }
      }
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    margin: 16px auto 0 auto;
    width: 313px;
    @include respond(middle) {
      width: 258px;
      transform: translateX(-19.4%);
    }
    @include respond(medium) {
      width: 213px;
    }
    @include respond(small) {
      width: 170px;
      margin: 16px auto 0;
      transform: translateX(-4%);
    }

    .svg-icon {
      width: 100%;
    }

    .description {
      margin: 13px 0 0 0;
      font-size: 16px;
      line-height: calc(20 / 16);
      text-align: center;
      color: $white;
      @include respond(middle) {
        margin: 10px 0 0 0;
      }
      @include respond(medium) {
        margin: 8px 0 0 0;
      }
    }
  }

  @import "_mobile-menu";
}